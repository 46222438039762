import React, { ReactNode } from "react";
import UpdateSiteDialog from "../../components/UpdateSiteDialog";
import { IRouteMeta } from "../../permitRoutes";
import { AuthContext } from "../AuthContext";
import { Login } from "../Login";
import AdminLayout from "./AdminLayout";
import PageLayout from "./PageLayout";

interface Props {
  route?: IRouteMeta;
  layout?: string;
}

interface State {}

class DefaultLayout extends React.Component<Props, State> {
  static contextType = AuthContext;

  get authenticated() {
    return this.context.authenticated;
  }

  render(): ReactNode {
    return (
      <>
        {(this.props.layout === "none" && <>{this.props.children}</>) ||
          (this.authenticated &&
            (!this.props.layout ||
              ((this.props.layout === "both" ||
                this.props.layout === "admin") && (
                <AdminLayout route={this.props.route}>
                  {this.props.children}
                </AdminLayout>
              )))) || (
            <PageLayout route={this.props.route}>
              {this.props.children}
              {!this.authenticated && (
                <div
                  style={{
                    display: this.props.route?.requiresAuth ? "block" : "none"
                  }}
                >
                  <Login />
                </div>
              )}
            </PageLayout>
          ) || <>{this.props.children}</>}
        <UpdateSiteDialog />
      </>
    );
  }
}

export default DefaultLayout;
