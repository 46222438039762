import React, { PureComponent, ReactNode } from 'react'
import DropdownLanguages from '../../Topbar/Dropdown/DropdownLanguages'
import i18n from '../../../../i18n'
import { withTranslation, WithTranslation } from "react-i18next";

interface Props {}
interface State {}

class TopbarLanguages extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			
		}
	}

	render(): ReactNode {
		return (
			<div className="kt-header__topbar-item kt-header__topbar-item--langs">
				<div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="10px,0px">
					<span className="kt-header__topbar-icon">
						{
							i18n.language === 'lt' &&
							<img className="" src={require("../../../../../theme/keen/sass/global/components/media/flags/064-lithuania.svg")} alt="" />
						}
						{
							i18n.language === 'en' &&
							<img className="" src={require("../../../../../theme/keen/sass/global/components/media/flags/226-united-states.svg")} alt="" />
						}
					</span>
				</div>
				<div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
					<DropdownLanguages />
				</div>
			</div>
		)
	}
}

export default withTranslation()(TopbarLanguages as React.ComponentType<WithTranslation>)
