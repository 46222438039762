import React, { PureComponent, ReactNode, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext, Authorization } from '../../../../AuthContext'
import { KeistiAtstovaujamaAsmeni } from '../../../../KeistiAtstovaujamaAsmeni'
import { Profilis } from '../../../../Profilis'

interface Props {
	context: Authorization;
}
interface State {
  showPersonChangeDialog: boolean
  showProfileDialog: boolean
  allowClose: boolean
}

class DropdownUserSolid extends React.Component<Props, State> {
	static contextType = AuthContext;
	constructor(props: Props) {
		super(props)
		this.state = {
			showPersonChangeDialog: false,
			showProfileDialog: false,
			allowClose: true,
    }
	}
	personChangeDialogToggle(toggle: boolean, e?: React.MouseEvent) {
    this.setState({
      showPersonChangeDialog: toggle,
		})
		e?.preventDefault()
	}
	profileDialogToggle(toggle: boolean, e?: React.MouseEvent) {
    this.setState({
      showProfileDialog: toggle,
	  allowClose: true,
		})
		e?.preventDefault()
  	}

	userContactNeedsUpdate(authorization: Authorization) {
		let contactInfo = authorization.atstovaujamasAsmuo?.contactInfo;
		if (contactInfo && (contactInfo.email.length == 0 || contactInfo.phone.length == 0 || contactInfo.address.length == 0)) {
			this.setState({
				showProfileDialog: true,
				allowClose: false,
			})
		}
	}

	componentDidMount() {
        this.userContactNeedsUpdate(this.props.context);
    }

	componentWillUnmount() {
        this.setState({});
    }
	
	render(): ReactNode {
		return (
			<div className="user-solid">
				{/* If more advanced logout menu needed, there is full example in keen themes */}
				<ul className="kt-nav">
					<li className="kt-nav__item">
						<Link onClick={(e) => this.personChangeDialogToggle(true, e)} to="/" className="kt-nav__link">
							<span className="kt-nav__link-icon"><i className="flaticon-users-1"></i></span>
							<span className="kt-nav__link-text">Keisti atstovaujamą asmenį</span>
						</Link>
					</li>
					<hr className="mt-2" />
					<li className="kt-nav__item">
						<Link onClick={(e) => this.profileDialogToggle(true, e)} to="/" className="kt-nav__link">
							<span className="kt-nav__link-icon"><i className="flaticon2-calendar-3"></i></span>
							<span className="kt-nav__link-text">Mano profilis</span>
						</Link>
					</li>
					<li className="kt-nav__item">
						<Link to="/pranesimai/gauti" className="kt-nav__link">
							<span className="kt-nav__link-icon"><i className="flaticon2-mail"></i></span>
							<span className="kt-nav__link-text">Gauti pranešimai</span>
						</Link>
					</li>
					<li className="kt-nav__item">
						<Link to="/pranesimai/issiusti" className="kt-nav__link">
							<span className="kt-nav__link-icon"><i className="flaticon2-mail"></i></span>
							<span className="kt-nav__link-text">Išsiųsti pranešimai </span>
						</Link>
					</li>
					{/* <li className="kt-nav__item">
						<Link to="/" className="kt-nav__link">
							<span className="kt-nav__link-icon"><i className="flaticon2-gear"></i></span>
							<span className="kt-nav__link-text">Parametrai</span>
						</Link>
					</li> */}
					<li className="kt-nav__separator kt-nav__separator--fit"></li>
					<li className="kt-nav__custom kt-space-between">
						<span></span>
						<a onClick={this.context.logout} className="btn btn-label-brand btn-upper btn-sm btn-bold">Atsijungti</a>
					</li>
				</ul>
				<KeistiAtstovaujamaAsmeni callback={() => this.personChangeDialogToggle(false)} show={this.state.showPersonChangeDialog}/>
				{
					this.state.showProfileDialog &&
					<Profilis onClose={() => this.profileDialogToggle(false)} show={true} allowCloseBtn={this.state.allowClose} />
				}
			</div>
		)
	}
}

export default DropdownUserSolid
