import { renderRoutes } from "react-router-config";
// needs to be imported for translations
import "./view/i18n";

import permitRoutes, { IRouteMeta } from "./permitRoutes";
import PageWrapper from "./view/PageWrapper";

// TODO: create components dynamically through webpack chunks!
// TODO: add i18n!
let routesMeta: IRouteMeta[] = [
  ...(permitRoutes as IRouteMeta[]),
  /**
   * MESSAGES
   */
  {
    requiresAuth: true,
    showAside: true,
    componentPath: "./ManoParaiskos",
    title: "Paraiškos",
    titleEn: "Applications",
    path: "/paraiskos",
    layout: "admin",
    category: "paraiskos",
    icon: "flaticon2-list-3",
    pageType: "category-page"
  },
  // {
  //   componentPath: './l05/L05LeidimoAtsiuntimas',
  //   title: 'Leidimai',
  //   path: '/leidimai/l05/:id',
  //   layout: 'both',
  //   category: 'leidimai',
  // },
  // {
  //   componentPath: './l05/L05LeidimoAtsiuntimas',
  //   title: 'Leidimai',
  //   path: '/leidimai/l05',
  //   layout: 'both',
  //   category: 'leidimai',
  // },
  {
    requiresAuth: true,
    showAside: true,
    componentPath: "./ManoLeidimai",
    title: "Leidimai",
    titleEn: "Permits",
    path: "/leidimai",
    layout: "admin",
    category: "leidimai",
    icon: "flaticon2-notepad",
    pageType: "category-page"
  },
  {
    requiresAuth: true,
    showAside: true,
    componentPath: "./l04/Kvotos/ManoKvotos",
    title: "Kvotos",
    titleEn: "Quotas",
    path: "/kvotos",
    layout: "admin",
    category: "kvotos",
    icon: "flaticon-clipboard",
    pageType: "category-page"
    // role: "ROLE_L04",
  },
  {
    requiresAuth: true,
    path: `/telkiniai/:id`,
    componentPath: `./ManoTelkinys`,
    layout: `admin`,
    role: "ROLE_L03"
  },
  {
    requiresAuth: true,
    showAside: true,
    componentPath: "./ManoTelkiniai",
    title: "Telkiniai",
    path: "/telkiniai",
    layout: "admin",
    category: "telkiniai",
    icon: "flaticon2-placeholder",
    pageType: "category-page",
    role: "ROLE_L03"
  },
  {
    requiresAuth: true,
    showAside: true,
    componentPath: "./ZvejuLeidimuSuvestine",
    title: "Žvejų leidimų suvestinė",
    path: "/zveju-leidimu-suvestine",
    layout: "admin",
    category: "zveju-leidimai",
    icon: "flaticon2-list-2",
    pageType: "category-page",
    role: "ROLE_L03"
  },
  // {
  //   requiresAuth: true,
  //   showAside: true,
  //   title: 'Kvotos',
  //   path: '/kvotos',
  //   layout: 'admin',
  //   category: 'kvotos',
  //   icon: 'flaticon2-notepad',
  //   pageType: 'category-page',
  //   role: '' // !!! add role here like in 'Telkiniai' route
  // },
  {
    requiresAuth: true,
    showAside: true,
    title: "Gauti",
    titleEn: "Received",
    path: "/pranesimai/gauti",
    componentPath: "./pranesimai/ReceivedMessages",
    layout: "admin",
    category: "pranesimai",
    icon: "flaticon2-chat-1"
  },
  {
    requiresAuth: true,
    showAside: true,
    title: "Išsiųsti",
    titleEn: "Sent",
    path: "/pranesimai/issiusti",
    componentPath: "./pranesimai/SentMessages",
    layout: "admin",
    category: "pranesimai",
    icon: "flaticon2-chat-1"
  },
  {
    requiresAuth: true,
    showAside: true,
    title: "Pranešimai",
    titleEn: "Messages",
    path: "/pranesimai/gauti",
    layout: "admin",
    category: "pranesimai",
    pageType: "category-page",
    icon: "flaticon2-chat-1"
  },
  /**
   * OTHER ROUTES
   */
  {
    requiresAuth: true,
    title: "Prisijunkite",
    path: "/login",
    category: "login",
    layout: "both",
    pageType: "strapi-page",
    hideWhiteBackground: true,
    centerContent: true
  },
  {
    requiresAuth: false,
    path: "/about",
    title: "Apie",
    category: "none",
    componentPath: "./Pages/About",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    path: "/contacts",
    title: "Kontaktai",
    category: "none",
    componentPath: "./Pages/Contacts",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    path: "/permits/:id",
    category: "none",
    componentPath: "./Pages/PermitsPage",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    title: "Leidimai",
    path: "/permits",
    category: "leidimai",
    componentPath: "./Pages/PermitsList",
    layout: "both",
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  // {
  //   requiresAuth: false,
  //   path: '/permits',
  //   category: 'none',
  //   componentPath: './Pages/PermitsList',
  //   layout: 'both',
  //   priority: 0,
  // },
  {
    requiresAuth: false,
    path: "/article/:id",
    title: "Pranešimas",
    category: "none",
    componentPath: "./Pages/Article",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    path: "/articles/:id",
    title: "Pranešimai",
    category: "none",
    componentPath: "./Pages/ArticlesList",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    path: "/articles",
    title: "Pranešimai",
    category: "none",
    componentPath: "./Pages/ArticlesList",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    path: "/faq",
    category: "none",
    componentPath: "./Pages/FaqPage",
    layout: "both",
    title: "Dažniausiai užduodami klausimai",
    titleEn: "Frequently asked questions",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    title: "Viešoji informacija",
    titleEn: "Public information",
    path: "/public-info",
    category: "none",
    componentPath: "./Pages/PublicInformation",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true,
    routes: [
      {
        requiresAuth: false,
        path: "/public-info/l01",
        component: PageWrapper,
        componentPath: "./l01/L01StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l02",
        component: PageWrapper,
        componentPath: "./l02/L02StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l03",
        component: PageWrapper,
        componentPath: "./l03/L03StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l04",
        component: PageWrapper,
        componentPath: "./l04/L04StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l05",
        component: PageWrapper,
        componentPath: "./l05/L05StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l06",
        component: PageWrapper,
        componentPath: "./l06/L06StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l07",
        component: PageWrapper,
        componentPath: "./l07/L07StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l08",
        component: PageWrapper,
        componentPath: "./l08/L08StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l09",
        component: PageWrapper,
        componentPath: "./l09/L09StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l10",
        component: PageWrapper,
        componentPath: "./l10/L10StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l11",
        component: PageWrapper,
        componentPath: "./l11/L11StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l12",
        component: PageWrapper,
        componentPath: "./l12/L12StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      },
      {
        requiresAuth: false,
        path: "/public-info/l13",
        component: PageWrapper,
        componentPath: "./l13/L13StatistineAtaskaita",
        category: "none",
        layout: "none",
        priority: 0
      }
    ]
  },
  {
    requiresAuth: false,
    path: "/map",
    category: "none",
    componentPath: "./Pages/MapPage",
    layout: "both",
    title: "Žemėlapis",
    titleEn: "Map",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    path: "/payment-success-notification/:type/:id",
    title: "Apmokėjimas sėkmingas",
    category: "none",
    componentPath: "./Payment/PaymentSuccessNotification",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    path: `/payment-success-notification`,
    title: "Apmokėjimas sėkmingas",
    category: "none",
    componentPath: "./Payment/PaymentSuccessNotification",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    path: "/payment-cancel-notification",
    title: "Apmokėjimas nutrauktas",
    category: "none",
    componentPath: "./Payment/PaymentCancelNotification",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  /**
   * FOOTER
   */
  {
    requiresAuth: false,
    title: "Apie mus",
    titleEn: "About us",
    path: "/about",
    category: "none",
    componentPath: "./Pages/About",
    layout: "both",
    priority: 0,
    block: "footer",
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  // {
  //     requiresAuth: false,
  //     title: "AM puslapis",
  //     titleEn: "AM site",
  //     path: "http://www.am.lt",
  //     category: "none",
  //     priority: 0,
  //     block: "footer",
  // },
  //   {
  //     requiresAuth: false,
  //     title: 'Kontaktai',
  //     titleEn: 'Contacts',
  //     path: 'http://am.lrv.lt/lt/pavaldziu-instituciju-kontaktai',
  //     category: 'none',
  //     priority: 0,
  //     block: 'footer',
  //     pageType: 'strapi-page',
  //     hideWhiteBackground: true,
  //   },
  {
    requiresAuth: false,
    title: "Kontaktai",
    titleEn: "Contacts",
    path: "/contacts",
    componentPath: "./Pages/Contacts",
    category: "none",
    priority: 0,
    block: "footer",
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    path: "/educationalMaterial",
    title: "Mokomoji medžiaga",
    titleEn: "Educational Material",
    category: "none",
    componentPath: "./Pages/EducationMaterial",
    layout: "both",
    priority: 0,
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  /**
   * Home
   */
  {
    requiresAuth: false,
    // title: 'Titulinis',
    path: "/",
    category: "none",
    componentPath: "./Pages/HomePage",
    layout: "both",
    priority: 0,
    menu: "bottom",
    pageType: "strapi-page",
    hideWhiteBackground: true
  },
  {
    requiresAuth: false,
    // title: 'test',
    path: "/test",
    category: "none",
    componentPath: "./TestPage",
    layout: "both",
    priority: 0,
    menu: "bottom",
    hideWhiteBackground: true,
    pageType: "form-page"
  }
];

/**
 * Every component which is loaded dynamically is loaded in PageWrapper
 */
// export const routes = routesMeta.map(r => {
//   r.component = PageWrapper as any
//   return r
// })

let _routes: IRouteMeta[] = [];

async function render() {
  if (!_routes.length) {
    // _routes = await StrapiSDK.getEntries('routes') as IRouteMeta[]
    // if (!_routes) {
    //   _routes = []
    // }
    // _routes.unshift(...routesMeta)
    _routes = routesMeta;
    _routes = _routes.map((r) => {
      r.component = PageWrapper;
      return r;
    });
    // move home page to the last in the router config, because '/' path overrides all other paths
    const homeIndex = _routes.findIndex((r) => r.path === "/");
    if (homeIndex >= 0) {
      let homeRoute = _routes[homeIndex];
      _routes.splice(homeIndex, 1);
      _routes.push(homeRoute);
    }
  }
  return renderRoutes(_routes);
}

export const publicRoutes = [
  {
    title: "Viešoji informacija",
    titleEn: "Public information",
    url: "/public-info"
  },
  { title: "Apie ALIS", titleEn: "About ALIS", url: "/about" },
  { title: "Pranešimai", titleEn: "Announcements", url: "/articles" },
  { title: "DUK", titleEn: "FAQ", url: "/faq" },
  { title: 'Žemėlapis', titleEn: 'Map', url: '/map' },
  // {
  //   title: "Mokomoji medžiaga",
  //   titleEn: "Educational Material",
  //   url: "/educationalMaterial"
  // }
];

export function titleByRoute(route: IRouteMeta) {
  if (route.title) {
    if (route.pageType === "category-page") return route.title;
    const category = _routes.find(
      (r) => r.category === route.category && r.pageType === "category-page"
    );
    return category?.title ? category.title + " - " + route.title : route.title;
  }
  return "";
}

export const routes = () => {
  return _routes;
};

export default render;
