import React, { ReactNode } from "react";
import { publicRoutes } from "../../../../router";
import { AuthContext } from "../../../AuthContext";
import "./HeaderBase.scss";
import HeaderMenu from "./HeaderMenu";
import TopbarBase from "./Topbar/TopbarBase";

interface Props {
  warningTitle?: string;
  showWarning?: boolean;
}
class HeaderBase extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): ReactNode {
    const links = [
      {
        title: "Viešoji informacija",
        titleEn: "Public information",
        url: "/public-info"
      },
      { title: "Apie ALIS", titleEn: "About ALIS", url: "/about" },
      { title: "Pranešimai", titleEn: "Announcements", url: "/articles" },
      { title: "DUK", titleEn: "FAQ", url: "/faq" },
      { title: "Žemėlapis", titleEn: "Map", url: "/map" }
      // {
      //   title: 'Mokimoji medžiaga',
      //   titleEn: 'Educational Material',
      //   url: '/educationalMaterial',
      // },
    ];
    return (
      <AuthContext.Consumer>
        {() => {
          return (
            <>
              <div
                id="kt_header"
                className={`kt-header kt-grid__item  kt-header--fixed`}
              >
                <HeaderMenu links={publicRoutes} />
                <TopbarBase
                  menu={publicRoutes}
                  children={this.props.children}
                />
              </div>
              {this.props.showWarning && (
                <div
                  style={{
                    position: "fixed",
                    width: "100%",
                    backgroundColor: "#FFEDCB",
                    textAlign: "center",
                    color: "red"
                  }}
                  className={`content-page kt-container kt-grid__item--fluid kt-grid__item`}
                >
                  {this.props.warningTitle}
                </div>
              )}
            </>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default HeaderBase;
