import { format, parse } from "date-fns";
import { alisDateFormat, alisLocalDateTimeFormat, alisZonedDateTimeFormat, emptySelectOptionValue } from "./types";
import { Classifier } from "./classifiers";

export interface AdresasDto {
    adresasTekstas?: string;
}

export function toDtoFormData<T extends {}>(
    values: T,
    customMappers: Partial<Record<keyof T, (field: keyof T, value: any, formData: FormData) => void>> = {}
): FormData {
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
        if (key in customMappers) {
            (customMappers[key as keyof T] as any)(key, value, formData);
        } else {
            defaultFormDataMapper(key, value, formData);
        }
    }
    return formData;
}

function defaultFormDataMapper(key: string, value: any, formData: FormData): void {
    if (value != null) {
        if (Array.isArray(value)) {
            value.forEach((subValue, index) => defaultFormDataMapper(`${key}[${index}]`, subValue, formData));
        } else if (value instanceof Date) {
            formData.set(key, value ? format(value, alisDateFormat) : "");
        } else if (value instanceof File || value instanceof Blob) {
            formData.set(key, value);
        } else if (typeof value === "boolean") {
            formData.set(key, value ? "true" : "false");
        } else if (typeof value === "object") {
            Object.entries(value).forEach(([prop, subValue]) => defaultFormDataMapper(`${key}.${prop}`, subValue, formData));
        } else {
            formData.set(key, String(value));
        }
    }
}

export function toClassifierDto<T extends Classifier>(value: string): T;
export function toClassifierDto<T extends Classifier>(value: string | undefined): T | undefined;
export function toClassifierDto<T extends Classifier>(value?: string): T | undefined {
    return value ? ({ id: Number(value) } as any) : undefined;
}

export function fromClassifierDto(value?: Classifier): string {
    return value && value.id != null ? String(value.id) : emptySelectOptionValue;
}

export function toDateDto(value: Date): string;
export function toDateDto(value: Date | undefined): string | undefined;
export function toDateDto(value?: Date): string | undefined {
    return value ? format(value, alisDateFormat) : undefined;
}

export function fromDateDto(value: string): Date;
export function fromDateDto(value: string | undefined): Date | undefined;
export function fromDateDto(value?: string): Date | undefined {
    return value ? parse(value, alisDateFormat, new Date(0)) : undefined;
}

export function toDateTimeDto(value: Date): string;
export function toDateTimeDto(value: Date | undefined): string | undefined;
export function toDateTimeDto(value?: Date): string | undefined {
    return value ? format(value, "yyyy-MM-dd'T'HH:mm:ss'Z'") : undefined;
}

export function fromZonedDateTimeDto(value: string): Date;
export function fromZonedDateTimeDto(value: string | undefined): Date | undefined;
export function fromZonedDateTimeDto(value?: string): Date | undefined {
    return value ? parse(value, alisZonedDateTimeFormat, new Date(0)) : undefined;
}

export function toLocalDateTimeDto(value: Date): string;
export function toLocalDateTimeDto(value: Date | undefined): string | undefined;
export function toLocalDateTimeDto(value?: Date): string | undefined {
    return value ? format(value, alisLocalDateTimeFormat) : undefined;
}

export function fromLocalDateTimeDto(value: string): Date;
export function fromLocalDateTimeDto(value: string | undefined): Date | undefined;
export function fromLocalDateTimeDto(value?: string): Date | undefined {
    return value ? parse(value, alisLocalDateTimeFormat, new Date(0)) : undefined;
}

export function toNumberDto(value: string): number;
export function toNumberDto(value: string | undefined): number | undefined;
export function toNumberDto(value?: string): number | undefined {
    return value ? Number(value) : undefined;
}

export function fromNumberDto(value?: number): string {
    return value != null ? String(value) : "";
}

export function toMoneyDto(value: string): number;
export function toMoneyDto(value: string | undefined): number | undefined;
export function toMoneyDto(value?: string): number | undefined {
    return value ? Math.round(Number(value) * 100) : undefined;
}

export function fromMoneyDto(value?: number): string {
    return value != null ? (value / 100).toFixed(2) : "";
}

export function toStringDto(value: string): string;
export function toStringDto(value: string | undefined): string | undefined;
export function toStringDto(value?: string): string | undefined {
    return value ? value : undefined;
}

export function fromStringDto(value?: string): string {
    return value ? value : "";
}

export function toAdresasDto(value: string): AdresasDto {
    return { adresasTekstas: value };
}
export function fromAdresasDto(value?: AdresasDto): string {
    return value && value.adresasTekstas != null ? value.adresasTekstas : "";
}

export function toKgDto(value: string): string;
export function toKgDto(value: string | undefined): string | undefined;
export function toKgDto(value?: string): string | undefined {
    if (value != null && value !== "") {
        return String(Math.round(Number(value) * 1000));
    } else {
        return undefined;
    }
}

export function fromKgDto(value?: string): string {
    let kg = new String();
    kg = value != null ? (Number(value) / 1000).toFixed(3) : "";
    if (kg.endsWith(".000")) {
        kg = kg.replace(".000", "");
    }
    return String(kg);
}
