import fetch from "cross-fetch";
import { createBrowserHistory } from "history";
import { Modal } from "react-bootstrap";
import { ErrorMessage } from "../view/ErrorContext";

export interface UserToken {
    token: string;
    type: string;
}

export interface ApiParams {
    userToken?: UserToken;
    uriPath?: string[];

    [additionalParam: string]: any;
}

export function getXsrfTokenCookie():string {
    const value =  document.cookie;
    const res = value.match(/XSRF-TOKEN=([^;]+)/g);

    const parts = res && res.pop()?.split("=");
    
    if (parts && parts.length == 2) {
        return parts[1];
    } 
    
    return '';
}

export function fetchConfig(method: string, userToken: UserToken | undefined, contentType: string | null = "application/json"): RequestInit {
    const headers: any = {};

    if (contentType) {
        headers["Content-Type"] = contentType;
    }

    if (userToken) {
        headers["Authorization"] = `${userToken.type} ${userToken.token}`;
    }

    if(method != 'GET' && getXsrfTokenCookie()){
        headers["x-xsrf-token"] = getXsrfTokenCookie();
    }

    return {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: headers,
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
    };
}

// TODO consider using URLSearchParams for query params
function apiUri(relativeUri: string, params: ApiParams) {
    let uri = process.env.REACT_APP_API_URL + relativeUri;

    if (!params) {
        return uri;
    }

    if (params.uriPath) {
        for (const path of params.uriPath) {
            uri += `/${encodeURIComponent(path)}`;
        }
    }

    let firstParam = true;
    for (const [param, value] of Object.entries(params)) {
        if (value != null && param !== "userToken" && param !== "uriPath") {
            if (firstParam) {
                uri += "?";
                firstParam = false;
            } else {
                uri += "&";
            }

            uri += `${param}=${encodeURIComponent(value)}`;
        }
    }

    return uri;
}

export interface ApiErrorDebugInfo {
    error: string;
    [key: string]: any;
}

export class ApiError extends Error {
    constructor(public uri: string, public status: number, public message: string, public debugInfo: ApiErrorDebugInfo, public causedBy?: Error) {
        super();
    }
}

export type ApiResponsePayloadExtractor = (response: Response) => any;

const jsonPayloadExtractor: ApiResponsePayloadExtractor = async (response: Response) => {
    return await response.json();
};

export const textPayloadExtractor: ApiResponsePayloadExtractor = async (response: Response) => {
    return await response.text();
};

async function apiCall(
    relativeUri: string,
    params: ApiParams,
    payloadExtractor: ApiResponsePayloadExtractor = jsonPayloadExtractor,
    conf: RequestInit
): Promise<any> {
    try {
        const response: Response = await fetch(apiUri(relativeUri, params), conf);
        if (response.ok) {
            let payload;
            if (response.status === 204) {
                payload = null;
            } else {
                payload = payloadExtractor(response);
            }

            return payload;
        } else {
            let errorInfo = await response.json();
            if(response.status === 400){                    
                if (errorInfo.errors && errorInfo.errors.globalErrors && errorInfo.errors.globalErrors.length > 0) {
                    errorInfo.error = errorInfo.errors.globalErrors.join("; ");
                } else if (errorInfo.errors && errorInfo.errors.fieldErrors) {
                    for (const [key, value] of Object.entries(errorInfo.errors.fieldErrors)) {
                        if (!!!errorInfo.error) {
                            let value2: any = value;
                            errorInfo.error = value2.join(";");
                        } else {
                            let value2: any = value;
                            errorInfo.error = errorInfo.error + value2.join(";");
                        }
                    }
                } else if (errorInfo.message) {
                    errorInfo.error = errorInfo.message;
                }
            } else if (response.status === 401) {
                errorInfo = { error: "Jūsų sesija baigėsi. Prisijungkite iš naujo." };
                window.setTimeout(function () {
                    window.location.reload();
                }, 2000);
            } else if (response.status === 504) {
                errorInfo = { error: "504 Gateway Timeout" };
            }
            console.log("API call response was not OK.", relativeUri, conf, response, errorInfo);
            throw new ApiError(relativeUri, response.status, errorInfo.error, "" as any);
        }
    } catch (e) {
        if (e instanceof ApiError) {
            throw e;
        }
        console.log("API call error was not handled.", relativeUri, conf, e, e.stack);
        throw new ApiError(relativeUri, -1, e.message, { error: e.message }, e);
    }
}

export function apiGet(relativeUri: string, params: ApiParams, payloadExtractor?: ApiResponsePayloadExtractor): Promise<any> {
    return apiCall(relativeUri, params, payloadExtractor, fetchConfig("GET", params ? params.userToken : undefined));
}

export function apiDelete(relativeUri: string, params: ApiParams, payloadExtractor?: ApiResponsePayloadExtractor): Promise<any> {
    return apiCall(relativeUri, params, payloadExtractor, fetchConfig("DELETE", params ? params.userToken : undefined));
}

export function apiPost(
    relativeUri: string,
    params: ApiParams,
    body: any,
    contentType: string = "application/json",
    payloadExtractor?: ApiResponsePayloadExtractor
): Promise<any> {
    return apiCallWithBody(relativeUri, "POST", params, body, contentType, payloadExtractor);
}

export function apiPut(
    relativeUri: string,
    params: ApiParams,
    body: any,
    contentType: string = "application/json",
    payloadExtractor?: ApiResponsePayloadExtractor
): Promise<any> {
    return apiCallWithBody(relativeUri, "PUT", params, body, contentType, payloadExtractor);
}

function apiCallWithBody(
    relativeUri: string,
    method: "PUT" | "POST",
    params: ApiParams,
    body: any,
    contentType: string | null = "application/json",
    payloadExtractor?: ApiResponsePayloadExtractor
): Promise<any> {
    if (body instanceof FormData) {
        // browser will auto set content type for form data
        contentType = null;
    } else if (contentType === "application/json") {
        body = body ? JSON.stringify(body) : null;
    }
    const conf = {
        ...fetchConfig(method, params ? params.userToken : undefined, contentType),
        body,
    };
    return apiCall(relativeUri, params, payloadExtractor, conf);
}

// PDFs

function pdfUri(relativeUri: string, params?: ApiParams) {
    let uri = process.env.REACT_APP_PDF_REPORT + relativeUri;

    if (!params) {
        return uri;
    }

    if (params.uriPath) {
        for (const path of params.uriPath) {
            uri += `/${encodeURIComponent(path)}`;
        }
    }

    let firstParam = true;
    for (const [param, value] of Object.entries(params)) {
        if (value != null && param !== "userToken" && param !== "uriPath") {
            if (firstParam) {
                uri += "?";
                firstParam = false;
            } else {
                uri += "&";
            }

            uri += `${param}=${encodeURIComponent(value)}`;
        }
    }

    return uri;
}

async function pdfCall(relativeUri: string, payloadExtractor: ApiResponsePayloadExtractor = jsonPayloadExtractor, conf: RequestInit): Promise<any> {
    try {
        const response: Response = await fetch(pdfUri(relativeUri), conf);
        if (response.ok) {
            let payload;
            if (response.status === 204) {
                payload = null;
            } else {
                payload = payloadExtractor(response);
            }

            return payload;
        } else {
            let errorInfo;
            if (response.status !== 401) {
                errorInfo = await response.json();
            } else {
                errorInfo = { error: "action.all.unauthorized" };
            }
            console.log("API call response was not OK.", relativeUri, conf, response, errorInfo);
            throw new ApiError(relativeUri, response.status, errorInfo.error, errorInfo);
        }
    } catch (e) {
        if (e instanceof ApiError) {
            throw e;
        }
        console.log("API call error was not handled.", relativeUri, conf, e, e.stack);
        throw new ApiError(relativeUri, -1, e.message, { error: e.message }, e);
    }
}

export function pdfGet(relativeUri: string, payloadExtractor?: ApiResponsePayloadExtractor): Promise<any> {
    return pdfCall(relativeUri, payloadExtractor, fetchConfig("GET", undefined));
}

// new PDF getting

function pdfUriV2(relativeUri: string, params?: ApiParams) {
    let uri = process.env.REACT_APP_API_URL + relativeUri;
    //let uri = process.env.REACT_APP_PDF_REPORT + relativeUri;

    if (!params) {
        return uri;
    }

    if (params.uriPath) {
        for (const path of params.uriPath) {
            uri += `/${encodeURIComponent(path)}`;
        }
    }

    let firstParam = true;
    for (const [param, value] of Object.entries(params)) {
        if (value != null && param !== "userToken" && param !== "uriPath") {
            if (firstParam) {
                uri += "?";
                firstParam = false;
            } else {
                uri += "&";
            }

            uri += `${param}=${encodeURIComponent(value)}`;
        }
    }

    return uri;
}

async function pdfCallV2(relativeUri: string, payloadExtractor: ApiResponsePayloadExtractor = jsonPayloadExtractor, conf: RequestInit): Promise<any> {
    try {
        const response: Response = await fetch(pdfUriV2(relativeUri), conf);
        if (response.ok) {
            let payload;
            if (response.status === 204) {
                payload = null;
            } else {
                payload = payloadExtractor(response);
            }

            return payload;
        } else {
            let errorInfo;
            if (response.status !== 401) {
                errorInfo = await response.json();
            } else {
                errorInfo = { error: "action.all.unauthorized" };
            }
            console.log("API call response was not OK.", relativeUri, conf, response, errorInfo);
            throw new ApiError(relativeUri, response.status, errorInfo.error, errorInfo);
        }
    } catch (e) {
        if (e instanceof ApiError) {
            throw e;
        }
        console.log("API call error was not handled.", relativeUri, conf, e, e.stack);
        throw new ApiError(relativeUri, -1, e.message, { error: e.message }, e);
    }
}

export function pdfGetV2(relativeUri: string, payloadExtractor?: ApiResponsePayloadExtractor): Promise<any> {
    return pdfCallV2(relativeUri, payloadExtractor, fetchConfig("GET", undefined));
}
