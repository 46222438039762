import StrapiSDK from './StrapiSDK'
import { IFaqTags, IQuestions } from './types'

export const QUESTIONS_ENDPOINT = 'questions'

let _q: IQuestions[] = []

export class Questions {
  static async getQuestions(locale: string) {
    _q = await StrapiSDK.getEntries(QUESTIONS_ENDPOINT+`?_locale=${locale}`) as IQuestions[]
    if (_q.length === 0) _q = await StrapiSDK.getEntries(QUESTIONS_ENDPOINT) as IQuestions[]
    return _q
  }
  static async getQuestionsByFaqTagUID(locale: string, uid?: string) {
    if (!uid || uid === '') return await this.getQuestions(locale)
    return (await this.getQuestions(locale)).filter(q => q.faq_tags.find(f => f.uid === uid))
  }
  static getLoadedQuestionsByFaqTagUID(uid?: string) {
    if (_q.length === 0) console.error('"Questions.getQuestions()" is not called called before loading existing Questions or no connection to the server')
    if (uid && uid !== '') {
      return _q.filter(q => q.faq_tags.find(f => f.uid === uid))
    }
    return _q
  }
}