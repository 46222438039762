import { TFunction } from "i18next";
import * as yup from "yup";
import { ParaiskaFileValues } from "../../resources/paraiskos";
import { ArraySchema } from "yup";

export function setValidationSchemaLocale(t: TFunction): void {
    yup.setLocale({
        string: {
            length: (params) => t("validation.length", { length: params.length }),
            email: t("validation.email"),
        },
        mixed: {
            required: t("validation.required"),
        },
        number: {
            min: (params) => t("validation.min", { min: params.min }),
            positive: t("validation.positive"),
        },
        array: {
            min: (params) => t("validation.arrayMin", { min: params.min }),
        },
    });
}

export function yupPersonCode(t: TFunction) {
    return yup
        .string()
        .matches(/^\d{11}$/, t("validation.personCode"))
        .matches(/^[3-6]\d{10}$/, t("validation.personCodeFormat"));
}

export function yupPersonCodeOrDate(t: TFunction) {
    return yup.string().test("valid-personCodeOrDate", t("validation.invalidPersonCodeOrDate"), (value: string) => {
        if (value && value.match(/^\d{11}$/) && value.match(/^[3-6]\d{10}$/)) {
            return validPersonCode(value);
        } else if (value && value.match(/^\d{4}[\-](0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])$/)) {
            const dateParse = value.split("-");
            const date = new Date(Number(dateParse[0]), Number(dateParse[1]) - 1, Number(dateParse[2]));
            if (Number(dateParse[2]) == date.getDate()) {
                return true;
            }
        }
        return false;
    });
}

export function yupPhone(t: TFunction) {
    return yup.string().matches(/^\+370\d{8}$/, t("validation.phone"));
}

export function yupName(msg: string) {
    return yup.string().matches(/[A-ZĄ-Ž][A-Za-zĄ-Žą-ž ,.'-]{1,}(?:[A-ZĄ-Ž][A-Za-zĄ-Žą-ž ,.'-]*){0,}$/, msg);
}

export function yupPostcode(t: TFunction) {
    return yup.string().matches(/^\d{5}$/, t("validation.postcode"));
}

export function yupFiles(): ArraySchema<ParaiskaFileValues> {
    return yup.array<ParaiskaFileValues>(
        yup.object<ParaiskaFileValues>({
            id: yup.string().notRequired(),
            tipas: yup.string().required(),
            pavadinimas: yup.string().required(),
            file: yup.mixed<File>().when("fileUrl", (fileId: string, schema: any) => {
                return fileId ? schema.notRequired() : schema.required();
            }),
        })
    );
}

export function yupL03Files(t: TFunction): ArraySchema<ParaiskaFileValues> {
    return yup.array<ParaiskaFileValues>(
        yup
            .object<ParaiskaFileValues>({
                tipas: yup.string().required(),
                pavadinimas: yup.string().required(),
                file: yup.mixed<File>().when("fileUrl", (fileId: string, schema: any) => {
                    return fileId ? schema.notRequired() : schema.required();
                }),
            })
            .test({
                name: "NZT-type-validator",
                test: function (item) {
                    const nztVal = 53;
                    //const moksTyrAtaskVal = 54;

                    let typeFound = false;

                    let array = this.parent;
                    let arraySize = array.length;

                    if (!typeFound) {
                        for (let index = 0; index < arraySize; index++) {
                            let element = array[index];
                            let docTipas = element.tipas;

                            //let hasNext = index + 1 < arraySize ? true : false;

                            if (docTipas != undefined && docTipas == nztVal) {
                                typeFound = true;
                                break;
                            }
                        }
                    }

                    return typeFound ? typeFound : this.createError({ path: `${this.path}.tipas`, message: t("validation.nztFileRequired") });
                },
            })
    );
}

export function validPersonCode(code?: string) {
    if (code && code.match(/\d{11}/)) {
        let b = 1,
            c = 3,
            d = 0,
            e = 0;
        for (let i = 0; i < 10; i++) {
            const a = Number(code[i]);
            d += b * a;
            e += c * a;
            if (++b == 10) {
                b = 1;
            }
            if (++c == 10) {
                c = 1;
            }
        }
        d = d % 11;
        e = e % 11;
        let r = d == 10 ? (e == 10 ? 0 : e) : d;
        return Number(code[10]) == r;
    }
    return false;
}
