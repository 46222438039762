import React, { PureComponent, ReactNode } from 'react'
import i18n, { setLanguage } from '../../../../i18n'
import { Link } from 'react-router-dom'

interface Props {}
interface State {}

class DropdownLanguages extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			
		}
	}

	onLanguageChange(e: React.MouseEvent, lng: 'en' | 'lt') {
		e.preventDefault()
		setLanguage(lng)
	}

	render(): ReactNode {
		return (
			<ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
				<li className={`kt-nav__item${ i18n.language === 'en' ? ' kt-nav__item--active' : ''}`}>
					<Link to="&language=en" onClick={(e) => this.onLanguageChange(e, 'en')} className="kt-nav__link">
						<span className="kt-nav__link-icon"><img src={require("../../../../../theme/keen/sass/global/components/media/flags/226-united-states.svg")} alt="" /></span>
						<span className="kt-nav__link-text">English</span>
					</Link>
				</li>
				<li className={`kt-nav__item${ i18n.language === 'lt' ? ' kt-nav__item--active' : ''}`}>
					<Link to="&language=lt" onClick={(e) => this.onLanguageChange(e, 'lt')} className="kt-nav__link">
						<span className="kt-nav__link-icon"><img src={require("../../../../../theme/keen/sass/global/components/media/flags/064-lithuania.svg")} alt="" /></span>
						<span className="kt-nav__link-text">Lietuviškai</span>
					</Link>
				</li>
			</ul>
		)
	}
}

export default DropdownLanguages
