import { apiGet, apiPut, textPayloadExtractor } from "./api";
import { AlisUserProfile, CompanyContactInfo, ContactInfo, isCompanyContactInfo, Profile, ExtendedContactInfo } from "./types";
import { AdresasDto, fromStringDto } from "./dto";

interface ProfilisDto {
    adresas?: AdresasDto;
    epastas?: string;
    tel?: string;
    infoEpastu?: boolean;
    infoSms?: boolean;
    atstovaujamoAsmensProfilis?: AtstovaujamoAsmensProfilisDto;
}

interface AtstovaujamoAsmensProfilisDto {
    vardas?: string;
    pavarde?: string;
    pareigos?: string;
    adresas?: AdresasDto;
    epastas?: string;
    tel?: string;
    infoEpastu?: boolean;
    infoSms?: boolean;
}

export function fromProfilisDto(dto: ProfilisDto): AlisUserProfile {
    const contactInfo: ContactInfo = {
        address: dto.adresas ? fromStringDto(dto.adresas.adresasTekstas) : "",
        email: fromStringDto(dto.epastas),
        phone: fromStringDto(dto.tel),
        infoByEmail: !!dto.infoEpastu,
        infoByPhone: !!dto.infoSms,
    };

    let atstovaujamoAsmensContactInfo = contactInfo;
    if (dto.atstovaujamoAsmensProfilis) {
        atstovaujamoAsmensContactInfo = fromAtstovaujamoAsmensProfilisDto(dto.atstovaujamoAsmensProfilis);
    }

    return {
        contactInfo,
        atstovaujamoAsmensContactInfo,
    };
}

export function fromAtstovaujamoAsmensProfilisDto(dto: AtstovaujamoAsmensProfilisDto): ContactInfo | CompanyContactInfo {
    return {
        address: dto.adresas ? fromStringDto(dto.adresas.adresasTekstas) : "",
        email: fromStringDto(dto.epastas),
        phone: fromStringDto(dto.tel),
        infoByEmail: !!dto.infoEpastu,
        infoByPhone: !!dto.infoSms,
        position: fromStringDto(dto.pareigos),
        contact:
            dto.vardas || dto.pavarde
                ? {
                      firstName: fromStringDto(dto.vardas),
                      lastName: fromStringDto(dto.pavarde),
                  }
                : {
                      firstName: "",
                      lastName: "",
                  },
    };
}

function toProfilisDto(values: AlisUserProfile): ProfilisDto {
    return {
        adresas: { adresasTekstas: values.contactInfo.address },
        epastas: values.contactInfo.email,
        tel: values.contactInfo.phone,
        infoEpastu: values.contactInfo.infoByEmail,
        infoSms: values.contactInfo.infoByPhone,
        atstovaujamoAsmensProfilis: toAtstovaujamoAsmensProfilisDto(values.atstovaujamoAsmensContactInfo),
    };
}

function toAtstovaujamoAsmensProfilisDto(values: ContactInfo | CompanyContactInfo): AtstovaujamoAsmensProfilisDto {
    return {
        adresas: { adresasTekstas: values.address },
        epastas: values.email,
        tel: values.phone,
        infoEpastu: values.infoByEmail,
        infoSms: values.infoByPhone,
        vardas: isCompanyContactInfo(values) ? values.contact?.firstName : undefined,
        pavarde: isCompanyContactInfo(values) ? values.contact?.lastName : undefined,
        pareigos: isCompanyContactInfo(values) ? values.position : undefined,
    };
}

export async function readAlisProfile(atstovaujamoAsmensKodas?: string): Promise<AlisUserProfile> {
    return apiGet("/v1/profilis", { atstovaujamoAsmensKodas }).then(fromProfilisDto);
}

function toProfilisDto2(values: any): ProfilisDto {
    const atstovaujamoAsmensProfilisDto = {
        adresas: { adresasTekstas: values.contactInfo.address },
        epastas: values.contactInfo.email,
        tel: values.contactInfo.phone,
        infoEpastu: values.contactInfo.infoByEmail,
        infoSms: values.contactInfo.infoByPhone,
        pareigos: values.contactInfo.position,
        vardas: values.contactInfo.contactFirstName,
        pavarde: values.contactInfo.contactLastName,
    };

    return atstovaujamoAsmensProfilisDto;
}

export async function saveAlisProfile(values: any): Promise<Profile> {
    const userCode = values.code;
    const body = toProfilisDto2(values);
    const profile = await apiPut("/v1/profilis", { atstovaujamoAsmensKodas: userCode }, body);
    return toProfilisFromUserData(profile);
}

export async function checkAlisProfile(atstovaujamoAsmensKodas?: string): Promise<boolean> {
    return checkSingleAlisProfile().then((valid) => {
        if (!valid) {
            return false;
        }

        if (atstovaujamoAsmensKodas) {
            return checkSingleAlisProfile(atstovaujamoAsmensKodas);
        }

        return true;
    });
}

async function checkSingleAlisProfile(atstovaujamoAsmensKodas?: string): Promise<boolean> {
    return apiGet("/v1/profilis/validate", { atstovaujamoAsmensKodas }, textPayloadExtractor).then((content) => content === "true");
}

export function toProfilisFromUserData(dto: UserAtstovaujamasAsmuo): Profile {
    let code = dto.imonesKodas ? dto.imonesKodas : dto.asmensKodas;
    //code = code ? code : "";

    return {
        firstName: dto.vardas,
        lastName: dto.pavarde,
        code: code,
        contactInfo: { ...resolveProfileContactInfo(dto) },
        name: dto.imonesPavadinimas,
    };
}

export function resolveProfileContactInfo(dto: UserAtstovaujamasAsmuo): ExtendedContactInfo {
    return {
        address: dto.adresas ? fromStringDto(dto.adresas.adresasTekstas) : "",
        email: fromStringDto(dto.epastas),
        phone: fromStringDto(dto.tel),
        infoByEmail: !!dto.infoEpastu,
        infoByPhone: !!dto.infoSms,

        position: fromStringDto(dto.pareigos),
        isCompanyContact: !!dto.imonesKodas ? true : false,
        contactFirstName: fromStringDto(dto.vardas),
        contactLastName: fromStringDto(dto.pavarde),
    };
}

export interface UserData {
    id?: number;

    name: string;
    surname: string;
    username: string;
    asmensKodas: string;
    imonesKodas: string;
    email: string;

    authorities: UserAuthority[];

    atstovaujamiAsmenys: UserAtstovaujamasAsmuo[];

    active: boolean;
    enabled: boolean;
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    credentialsNonExpired: boolean;
    defaultProfileIndex: number;
}

export interface UserAuthority {
    authority: string;
}

export interface UserAtstovaujamasAsmuo {
    id: number;
    viispId: string;

    asmensKodas: string;
    vardas: string;
    pavarde: string;
    imonesKodas: string;
    imonesPavadinimas: string;

    pareigos: string;
    adresas: AdresasDto;
    epastas: string;
    tel: string;

    infoEpastu: boolean;
    infoSms: boolean;
}
