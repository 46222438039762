import React, { useEffect, useState } from 'react'
import socketIOClient from "socket.io-client";
import './UpdateSiteDialog.scss'
import { StrapiSDK } from '../StrapiService';

interface Props {}

function UpdateSiteDialog(props: Props) {
  const [showDialog, setShowDialog] = useState(false);
  const [timestamp, setTimestamp] = useState(0);
  const {} = props
  const close = () => {
    setShowDialog(false)
  }
  const reload = () => {
    close()
    window.location.reload()
  }
  useEffect(() => {
    // const socket = socketIOClient(StrapiSDK.strapiUrl);
    // socket.on("last-update", (data:string) => {
    //   const json = JSON.parse(data)
    //   if (typeof(json['last-update']) === 'string') {
    //     const t = new Date(json['last-update']).getTime()
    //     if (timestamp < t) {
    //       setTimestamp(t)
    //       setShowDialog(true);
    //     }
    //   }
    // });
  }, []);

  return (
    <>
      {
        showDialog &&
        <div className="update-site-dialog">
          <button onClick={close} className="close mt-4 btn btn-icon">
            <i className="la la-close"></i>
          </button>
          <div className="message">
            Turinys svetainėje pasikeitė, perkraukite svetainę.
          </div>
          <button onClick={reload} className="mt-4 btn btn-sm  btn-primary">Perkrauti</button>
        </div>
      }
    </>
  );
}

export default UpdateSiteDialog
