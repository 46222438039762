import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ErrorContext } from "../ErrorContext";
import { Formik } from "formik";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { AlisFormField } from "../form/AlisFormField";
import { postViisp, AsmuoValues } from "../../resources/auth";

export const TestinisPrisijungti: React.FC = () => {
    const { setError } = useContext(ErrorContext);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [profilis, setSetProfilis] = useState<AsmuoValues>({
        asmensKodas: "",
        vardas: "",
        pavarde: "",
        viispId: "",
        id: "",
        imonesKodas: "",
        imonesPavadinimas: "",
    });
    const doPost = useCallback(
        (values: AsmuoValues) => {
            postViisp(values)
                .then((values) => {
                    if (values.imonesKodas) {
                        history.push(`/login/viisp?ticket=${values.id}&imonesKodas=${values.imonesKodas}`);
                        // history.replace({pathname: `/login/viisp?ticket=${values.id}&imonesKodas=${values.imonesKodas}`});
                    } else {
                        history.push(`/login/viisp?ticket=${values.id}`);
                        // history.replace({pathname: `/login/viisp?ticket=${values.id}&imonesKodas=134311048`});
                    }

                    // window.location.reload();
                })
                .catch(setError);
        },
        [setError]
    );

    return (
        <>
            <div>
                <Formik
                    initialValues={profilis}
                    onSubmit={(values, formikHelpers) => {
                        doPost(values);
                    }}
                >
                    {({ handleSubmit, isSubmitting }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit} className="pt-3">
                                <Form.Label className="mt-2">TESTINIS PRISIJUNGIMAS:</Form.Label>
                                <Card>
                                    <Card.Header style={{ fontWeight: "bold", color: "darkorange" }}>VARTOTOJAS FIZINIS ASMUO</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md="3">
                                                <Form.Label className="mt-2">Vardas</Form.Label>
                                                <AlisFormField name="vardas">
                                                    <Form.Control type="text" />
                                                </AlisFormField>
                                            </Col>

                                            <Col md="3">
                                                <Form.Label className="mt-2">Pavardė</Form.Label>
                                                <AlisFormField name="pavarde">
                                                    <Form.Control type="text" />
                                                </AlisFormField>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="3">
                                                <Form.Label className="mt-2">Asmens kodas</Form.Label>
                                                <AlisFormField name="asmensKodas">
                                                    <Form.Control type="text" />
                                                </AlisFormField>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Header style={{ fontWeight: "bold", color: "darkorange" }}>VARTOTOJAS ĮMONĖ</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            {/* <Col md="3">

                            <Form.Label className="mt-2">Įmonės pavadinimas</Form.Label>
                            <AlisFormField name="imonesPavadinimas">
                                            <Form.Control type="text"/>
                            </AlisFormField>

                        </Col> */}

                                            <Col md="3">
                                                <Form.Label className="mt-2">Įmonės kodas</Form.Label>
                                                <AlisFormField name="imonesKodas">
                                                    <Form.Control type="text" />
                                                </AlisFormField>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Row>
                                    <Col md="12">
                                        <Button variant="outline-success" style={{ fontWeight: "bold" }} disabled={isSubmitting} type="submit">
                                            PRISIJUNGTI (TESTAS)
                                        </Button>
                                        <Button variant="outline-info" style={{ fontWeight: "bold", margin: "10px" }} onClick={history.goBack}>
                                            ATGAL
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};
