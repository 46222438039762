import React from "react";
import {FieldInputProps, FieldMetaProps, useField, useFormikContext} from "formik";
import Form from "react-bootstrap/Form";
import {Trans} from "react-i18next";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

library.add(faSpinner);
interface ValidatedFieldControlProps {
	isValid: boolean;
	isInvalid: boolean;
}

interface AlisFormFieldProps {
	name: string;
	pastaba?: string;
	children?: React.ReactElement;
	render?: (controlProps: FieldInputProps<any> & ValidatedFieldControlProps, controlFeedback: React.ReactElement, controlPastaba: React.ReactElement) => React.ReactElement;
	showSpinner?: boolean | false;
	className?: string
	hideError?: boolean
}

export function fieldValid(meta: FieldMetaProps<any>, submitCount: number): {isValid: boolean; isInvalid: boolean;} {
	return {
		isValid: meta.touched && !meta.error,
		isInvalid: (meta.touched || submitCount > 0) && !!meta.error,
	}
}

export const AlisFormField: React.FC<AlisFormFieldProps> = (props) => {
	const [field, meta] = useField(props.name);
	const {submitCount} = useFormikContext<any>();

	const fieldProps = {
		...field,
		...fieldValid(meta, submitCount),
	};

	if (props.children) {
		return (
			<>
				{React.cloneElement(props.children, {...fieldProps, ...props.children.props})}
				{props.showSpinner && <FontAwesomeIcon className="input-spinner" icon="spinner" spin/>}
				{
					!props.hideError &&
					<Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
				}
				<AlisFormFieldPastaba className={props.className} pastaba={props.pastaba} />
			</>
		);
	} else if (props.render) {
		return props.render(fieldProps, <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>, <AlisFormFieldPastaba pastaba={props.pastaba} />);
	}

	return null;
};

export const AlisFormFieldPastaba: React.FC<{pastaba?: string, className?: string}> = ({pastaba, className}) => {
	if (pastaba) {
		return (
			<small className={`form-text text-danger${className ? ' ' + className : ''}`}>
				<Trans i18nKey="common.paraiskosLaukoPastaba" tOptions={{pastaba}} />
			</small>
		)
	}

	return null;
};
