
import React, { PureComponent, ReactNode } from 'react'
import DropdownUserSolid from '../../Topbar/Dropdown/DropdownUserSolid'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
interface Props {
	t: TFunction;
}
interface State {}

class TopbarUserPC extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			
		}
	}

	render(): ReactNode {
		return (
			<div className="kt-header__topbar-item kt-header__topbar-item--user">
				<div className="kt-header__topbar-wrapper" data-offset="0px,0px">
					<Link to="/login" className="kt-header__topbar-user kt-rounded-">
						<span className="kt-header__topbar-welcome kt-hidden-mobile">{this.props.t("home.btn.login")}</span>
						<span className="kt-badge kt-badge--username kt-badge--lg kt-badge--brand kt-hidden kt-badge--bold">S</span>
					</Link>
				</div>
			</div>
		)
	}
}

export const TopbarUser: React.FC<{}> = ({}) => {

	const { t } = useTranslation();
  
	return (
		  <TopbarUserPC t={t} />
	);
}

export default TopbarUser
