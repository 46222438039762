import React from "react";
import {Authorization} from "./AuthContext";
import {toAuthorization} from "../resources/auth";
// import {Authorization, toAuthorization} from "../resources/auth";

export const userService = {
    login,
    // logout,
};

function login(username?: string, password?: string): Promise<Authorization> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            authorization: "Basic " + btoa(username + ":" + password)
        }
    };
    if (!username) {
        delete requestOptions.headers.authorization;
    }
    return fetch(process.env.REACT_APP_API_URL + `/auth/viisp/user-info`, requestOptions)
        .then(handleResponse)
        .then(user => {
                // login successful if there's a user in the response
                if (user) {
                    const authorization = toAuthorization(user);
                    return authorization;

                }
                return new Promise<Authorization>((resolve, reject) => {
                    // call resolve if the method succeeds
                    return reject(user);
                });
            }
        );
}

function handleResponse(response: Response): Promise<any> {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // location.reload(true);
                // history.push("/");
                console.log("got a 401 response. Staying anonymous?");
            }

            const error = (data && data.message) || response.statusText;
            throw Error(response.statusText);
        }

        return data;
    });
}

// const defaultValue: Authorization = new Authorization();
// export const AuthContext = createContext(defaultValue);
//
// export function useAuth() {
//     return useContext(AuthContext);
// }

// @ts-ignore
// function PrivateRoute({component: Component, ...rest}) {
//     const isAuthenticated = useAuth();
//
//     return (
//         <Route
//             {...rest}
//             render={props =>
//                 isAuthenticated ? (
//                     <Component {...props} />
//                 ) : (
//                     <Redirect to="/"/>
//                 )
//             }
//         />
//     );
// }

// export default PrivateRoute;