import React, {useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {isPerson, Profile} from "../resources/types";
import {AuthContext, Authorization} from "./AuthContext";
import './KeistiAtstovaujamaAsmeni.scss'

library.add(faSpinner);

interface Props {
    callback?: () => void;
    show ?: boolean;
}

export const KeistiAtstovaujamaAsmeni: React.FC<Props> = ({callback, show}) => {
    const auth = useContext(AuthContext);
    const handleClose = callback ? () => callback() : () => {};
    const {t} = useTranslation();

    const keistiAsmeni = async (asmuo: Profile) => {
        await auth.changePerson(asmuo)
        handleClose()
    }

    const atstovavimaiRows = auth.atstovaujamiAsmenys?.map((atstovavimas, i) => (
        <tr key={atstovavimas.code} className={auth.atstovaujamiAsmenys.length > 1 && auth.atstovaujamasAsmuo?.code === atstovavimas.code ? 'representative' : undefined}>
            <td>{isPerson(atstovavimas) ? t('common.person.name', atstovavimas) : atstovavimas.name}</td>
            <td>{atstovavimas.code}</td>
            <td className="text-right">
                <Button variant="outline-primary" type="button"
                        onClick={() => keistiAsmeni(atstovavimas)}>{t('keistiAtstovaujamaAsmeni.pasirinkti')}</Button>
            </td>
        </tr>
    ));

    return (
        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{t('keistiAtstovaujamaAsmeni.header')}</Modal.Title>
                <button id="modal-close" type="button" className="close" onClick={handleClose}>
                    <span>×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <Table hover className="change-representative">
                    <thead>
                    <tr>
                        <th>{t('keistiAtstovaujamaAsmeni.pavadinimas')}</th>
                        <th>{t('keistiAtstovaujamaAsmeni.kodas')}</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                        {atstovavimaiRows}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    );
};
