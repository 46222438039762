import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './view/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import renderRoutes from './router'

async function render() {
  const routes = await renderRoutes()
  ReactDOM.render(
    <BrowserRouter basename={process.env.REACT_APP_SITE_BASENAME ? process.env.REACT_APP_SITE_BASENAME : '/'}>
      <App routes={routes} />
    </BrowserRouter>,
    document.getElementById("root")
  )
}

render()
