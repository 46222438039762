import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { readViispTicket } from "../../resources/auth";
import Alert from "react-bootstrap/Alert";
import { Trans } from "react-i18next";

export const ViispAuthenticationStart: React.FC = () => {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [ticket, setTicket] = useState<string | null>(null);

  const login = useCallback(() => {
    setBusy(true);
    setError(null);
    readViispTicket()
      .then(setTicket)
      .catch(() => {
        setError("prisijungti.error");
        setBusy(false);
      });
  }, []);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (ticket && formRef.current) {
      formRef.current.submit();
    }
  }, [ticket]);

  if (ticket) {
    return (
      <form
        ref={formRef}
        name="REQUEST"
        action={process.env.REACT_APP_VIISP_URL}
        method="post"
      >
        <input type="hidden" name="ticket" value={ticket} />
      </form>
    );
  }

  return (
    <div
      style={{
        paddingTop: 10,
      }}
    >
      <Button className="m-1" disabled={busy} variant="success" onClick={login}>
        {busy ? (
          <Trans i18nKey="prisijungti.jungiamasi" />
        ) : (
          <Trans i18nKey="prisijungti.viisp" />
        )}
      </Button>
      {error && (
        <Alert variant="danger">
          <Trans i18nKey={error} />
        </Alert>
      )}
    </div>
  );
};
