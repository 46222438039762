import React from "react";
import { Person, Profile } from "../resources/types";
import { apiPost, getXsrfTokenCookie } from "../resources/api";

export class Authorization {
    user?: Person;
    atstovaujamasAsmuo?: Profile;
    roles?: string[];
    atstovaujamiAsmenys: Profile[] = [];
    authenticated: boolean = false;
    defaultProfileIndex = 0;
    updateAuthState?: (newAuth?: Authorization) => void;

    logout = () => {
        const headers: any = {};
        if (getXsrfTokenCookie()) {
            headers["x-xsrf-token"] = getXsrfTokenCookie();
        }
        fetch(process.env.REACT_APP_API_URL + "/v1/auth/logout", { method: "POST", headers: headers }).then((value) => {
            window.location.replace(process.env.PUBLIC_URL);
        });
    };

    changePerson = async (person: Profile) => {
        this.atstovaujamasAsmuo = person;
        const index = this.atstovaujamiAsmenys?.findIndex((p) => p === this.atstovaujamasAsmuo);
        await apiPost("/v1/profilis/keisti/" + index, {}, {});
        if (this.updateAuthState) this.updateAuthState();
    };

    updateAuth(auth: Authorization) {
        this.user = auth.user;
        this.atstovaujamasAsmuo = auth.atstovaujamasAsmuo;
        this.roles = auth.roles;
        this.atstovaujamiAsmenys = auth.atstovaujamiAsmenys;
        this.authenticated = auth.authenticated;
        if (this.updateAuthState) this.updateAuthState();
    }

    hasRole(role: string): boolean {
        return !!this.roles && this.roles.indexOf(role) > -1;
    }
}

const unauthorized: Authorization = new Authorization();
export const AuthContext = React.createContext(unauthorized);
