import React, { useMemo, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Notifications } from "./Notifications";
import { AuthContext, Authorization } from "./AuthContext";
import { userService } from "./UserService";
import { ErrorBoundary } from "./ErrorBoundary";

import "../theme/default/style.scss";
import { useTranslation } from "react-i18next";
import { LocaleContext } from "./LocaleContext";

interface Props {
    routes: JSX.Element;
}

const App: React.FC<Props> = ({ routes }) => {
    const { i18n } = useTranslation();
    const auth = useContext(AuthContext);
    const history = useHistory();
    const [authState, setAuthState] = useState(auth);

    const updateAuthState = (newAuth?: Authorization) => {
        // REACTIVITY ISSUES INSIDE THE OLD CODE!!!!!!!!!!!!!
        // for provider we should always use state if we want reactive values
        setAuthState(Object.assign({}, newAuth ? newAuth : auth));
    };
    auth.updateAuthState = updateAuthState;

    useMemo(() => {
        if (!auth.authenticated) {
            const location = history.location;
            userService
                .login(undefined, undefined)
                .then((value) => {
                    auth.updateAuth(value);
                    history.push(location);
                })
                .catch(() => {
                    // if (location.pathname.search('/login') < 0) {
                    //   history.push("/login");
                    // }
                });
        }
    }, []);

    return (
        <ErrorBoundary>
            <LocaleContext.Provider value={i18n.language}>
                <AuthContext.Provider value={authState}>
                    <Notifications>{routes}</Notifications>
                </AuthContext.Provider>
            </LocaleContext.Provider>
        </ErrorBoundary>
    );
};

export default App;
