import StrapiSDK from './StrapiSDK'
import { IArticle, IAbout } from './types'

export const ARTICLES_ENDPOINT = `articles` // only return faq tags with questions

let _articles: IArticle[] = []
let _articlesCount = 0
let _permitsListArticles: IArticle[] = []

export class Articles {
  static get articlesPerPage() { return 20 }
  static async getArticlesWithLimit(locale: string, start?: Number, limit?: Number) {
    const localizedArticles = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?_sort=createdAt:DESC&_start=${start ? start : ''}&_limit=${limit ? limit : '-1'}&_locale=${locale}`) as IArticle[]
    const defaultArticles = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?_sort=createdAt:DESC&_start=${start ? start : ''}&_limit=${limit ? limit : '-1'}`) as IArticle[]
    return localizedArticles.length > 0 ? localizedArticles : defaultArticles;
    //return await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?_sort=createdAt:DESC&_start=${start ? start : ''}&_limit=${limit ? limit : '-1'}`) as IArticle[]
  }
  static async getArticles(locale: string) {
    if (_articles.length === 0) {
      const localizedArticles = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?_sort=createdAt:DESC&_limit=20&_locale=${locale}`) as IArticle[]
      const defaultArticles = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?_sort=createdAt:DESC&_limit=20`) as IArticle[]
      localizedArticles.length > 0 ? _articles = localizedArticles : _articles = defaultArticles;
      //_articles = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?_sort=createdAt:DESC&_limit=20`) as IArticle[]
    }
    return _articles
  }
  static async getArticlesCount(locale: string) {
    if (_articlesCount === 0) {
      const localizedCount = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}/count?_locale=${locale}`)
      const count = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}/count`)

      // @ts-ignore - strapi is wrongly typed and returns number
      if (localizedCount !== NaN) { _articlesCount = localizedCount
      // @ts-ignore - strapi is wrongly typed and returns number
      } else if (count !== NaN) _articlesCount = count
    }
    return _articlesCount
  }
  static async getArticleBySlug(slug: string, locale: string) {
    const fromList = _articles.findIndex(a=> a.slug === slug)
    if (fromList > -1) {
      return _articles[fromList]
    }
    const localizedArticles = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?slug=${slug}&_locale=${locale}`) as IArticle[]
    if (localizedArticles.length > 0) {
      return localizedArticles[0]  
    }
    const articles = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?slug=${slug}`) as IArticle[]
    if (articles.length > 0) {
      return articles[0]
    }
    return undefined
  }
  static async getLatestArticles(count: number) {
    if (_articles.length >= count) {
      return _articles.slice(0, count)
    }
    return await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?_sort=createdAt:DESC&_limit=${count}`) as IArticle[]
  }
  static async getAboutArticle(locale: string) {
    const about = await StrapiSDK.getEntries(`about?_locale=${locale}`) as IAbout[];
    const aboutDefault = await StrapiSDK.getEntries(`about`) as IAbout[];
    return Array.isArray(about) ? aboutDefault : about;
  }
  static async getPermitsListArticles() {
    if (_permitsListArticles.length === 0) {
      _permitsListArticles = await StrapiSDK.getEntries(`${ARTICLES_ENDPOINT}?showInPermitsList=true&_sort=createdAt:DESC&_limit=1`) as IArticle[]
    }
    return _permitsListArticles
  }
  static getArticleExerpt(content?: string, charsCount:number = 250) {
    if (content) return content.replace(/<[^>]*>?/gm, '').slice(0, charsCount) + "..."
    return ''
  }
}