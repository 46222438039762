import { RouteConfig } from "react-router-config";
// import CategoryWrapper from './view/CategoryWrapper'
import PageWrapper from "./view/PageWrapper";

interface IArticle {
    content: string;
    title: string;
}

export type IPageType = "strapi-page" | "form-page" | "category-page";

/**
 * @path path to the route
 * @title title of the route
 * @category category name, such as _L03_
 * @requiresAuth if true, then this link needs authentication and will be shown only if authenticated
 * @block block name where to show the menu _aside_, _footer_, etc.
 * @routes recursive interface with the same properties as the parent
 */
export interface IRouteMeta extends RouteConfig {
    path: string; // we change _path_ from optional to required
    title?: string;
    titleEn?: string;
    componentPath?: string;
    hide?: boolean;
    category?: string;
    requiresAuth?: boolean;
    menu?: "top" | "bottom" | "both";
    routes?: Partial<IRouteMeta>[];
    article?: IArticle;
    page?: IArticle;
    layout?: "admin" | "page" | "both" | "none"; // both - means admin and page (not authorized) layouts
    priority?: number;
    icon?: string;
    role?: "ROLE_L03" | "ROLE_L04"; // add all roles for all routes, that are required to show menus
    type?: string; // type of the component if the route is provided without ':type'
    centerContent?: boolean;
    pageType?: IPageType;
    hideWhiteBackground?: boolean;
}

// creates routes automatically by category and title,
// if title is '' - then no category is created
const categoryTitles: { category: string }[] = [
    { category: "l01" },
    { category: "l02" },
    { category: "l02_leidimo_tikslinimas" },
    { category: "l03" },
    { category: "l04" },
    { category: "l04_leidimo_tikslinimas" },
    // 5th category does not comply to the standards, so we will create it manually
    // Mėgėjų žvejybos leidimas
    { category: "l05_1" },
    { category: "l05_2" },
    { category: "l06_1" },
    { category: "l06_2" },
    { category: "l07" },
    // 8th category does not comply to the standards, so we will create it manually
    // Leidimas kirsti mišką
    { category: "l08_1" },
    { category: "l08_2" },
    { category: "l08_3" },
    { category: "l09" },
    { category: "l09_leidimo_keitimas" },
    { category: "l09_leidimo_tikslinimas" },
    { category: "l10" },
    { category: "l10_leidimo_keitimas" },
    { category: "l10_leidimo_tikslinimas" },
    { category: "l11" },
    { category: "l11_leidimo_tikslinimas" },
    { category: "l12" },
    { category: "l12_leidimo_tikslinimas" },
    { category: "l13" },
    { category: "l13_leidimo_tikslinimas" },
    { category: "l14_1" },
    { category: "l14_2" },
    { category: "l16_1" },
    { category: "l16_2" },
    { category: "l16_3" }
];

function removeSlashes(id: string) {
    return id.replace("_1", "").replace("_2", "").replace("_3", "").replace("_LEIDIMO_TIKSLINIMAS", "").replace("_LEIDIMO_KEITIMAS", "");
}

function getPermitTitle(slug: string) {
    slug = slug.replace("_", ".");
    const titles: { [Key in string]: string } = {
        "L05.1": "L05.1 - Žvejo mėgėjo bilietas",
        "L05.2": "L05.2 - Mėgėjų žvejybos leidimas",
        "L06.1": "L06.1 - Žvejo mėgėjo kortelė",
        "L06.2": "L06.2 - Žvejo mėgėjo kortelė lašišoms ar šlakiams",
        "L11.LEIDIMO_TIKSLINIMAS": "L11. TIKSLINIMAS - Leidimas",
        "L12.LEIDIMO_TIKSLINIMAS": "L12. TIKSLINIMAS - Leidimas",
    };
    return titles[slug] ? titles[slug] : `${slug} - Leidimas`;
}

function setCategory(slug: string): IRouteMeta[] {
    slug = slug.toUpperCase();
    const pathId = removeSlashes(slug);
    return [
        // {
        //   requiresAuth: true,
        //   path: `/ataskaitos/${slug}/:id`,
        //   componentPath: `./${pathId.toLowerCase()}/${pathId}StatistineAtaskaita`,
        //   layout: `admin`,
        // },
        // {
        //   requiresAuth: true,
        //   path: `/ataskaitos/${slug}`,
        //   componentPath: `./${pathId.toLowerCase()}/${pathId}StatistineAtaskaita`,
        //   layout: `admin`,
        // },
        {
            requiresAuth: !slug.includes("L05") && !slug.includes("L06"),
            path: `/atsisiusti/${pathId}/:id`,
            title: `Atsisiųsti leidimą`,
            titleEn: `Download permit`,
            // type: slug,
            componentPath: `./${pathId.toLowerCase()}/${pathId}LeidimoAtsiuntimas`,
            layout: `both`,
            centerContent: true,
            hideWhiteBackground: true,
        },
        {
            requiresAuth: !slug.includes("L05") && !slug.includes("L06"),
            path: `/atsisiusti/${pathId}`,
            title: `Atsisiųsti leidimą`,
            titleEn: `Download permit`,
            // type: slug,
            componentPath: `./${pathId.toLowerCase()}/${pathId}LeidimoAtsiuntimas`,
            layout: `both`,
            centerContent: true,
            hideWhiteBackground: true,
        },
        {
            requiresAuth: !slug.includes("L05") && !slug.includes("L06"),
            path: `/leidimai/${slug}/:id`,
            title: !slug.includes("L05") && !slug.includes("L06") ? undefined : getPermitTitle(slug),
            type: slug,
            componentPath: `./${pathId.toLowerCase()}/${pathId}Leidimas`,
            layout: `admin`,
            pageType: "form-page",
            centerContent: true,
            hideWhiteBackground: !slug.includes("L05") && !slug.includes("L06"),
        },
        {
            requiresAuth: !slug.includes("L05"),
            path: `/leidimai/${slug}`,
            type: slug,
            componentPath: `./${pathId.toLowerCase()}/${pathId}Leidimas`,
            layout: `admin`,
        },
        {
            requiresAuth: !slug.includes("L05") && !slug.includes("L06"), // all requires authentification except L05 and L06
            title: !slug.includes("L05") && !slug.includes("L06") ? undefined : `Įveskite leidimo duomenis`, // getPermitTitle(slug).replace('leidimas', 'paraiška').replace('Leidimas', 'Paraiška'),
            titleEn: !slug.includes("L05") && !slug.includes("L06") ? undefined : `Enter permit information`,
            type: slug,
            path: `/paraiskos/${slug}/:id`,
            componentPath: `./${pathId.toLowerCase()}/${pathId}Paraiska`,
            layout: `admin`,
            centerContent: !slug.includes("L05") && !slug.includes("L06"),
            pageType: "form-page",
            hideWhiteBackground: !slug.includes("L05") && !slug.includes("L06"),
        },
        {
            requiresAuth: !slug.includes("L05") && !slug.includes("L06"), // all requires authentification except L05 and L06
            title: !slug.includes("L05") && !slug.includes("L06") ? undefined : `Įveskite leidimo duomenis`,
            titleEn: !slug.includes("L05") && !slug.includes("L06") ? undefined : `Enter permit information`,
            type: slug,
            path: `/paraiskos/${slug}`,
            componentPath: `./${pathId.toLowerCase()}/${pathId}Paraiska`,
            layout: `both`,
            pageType: "form-page",
            hideWhiteBackground: !slug.includes("L05") && !slug.includes("L06"),
        },
        {
            path: `/${slug}`,
            layout: `page`,
            article: {
                title: "",
                content: `Kategorijos puslapis<br/><a href="/#/${slug}/paraiskos">Peržiūrėti paraišką</a>
                                      <br/><a href="/#/${slug}/ataskaitos">Peržiūrėti ataskaitą</a>`,
            },
        },
    ];
}

function setCategories(): Partial<IRouteMeta>[] {
    return categoryTitles.map((ct) => setCategory(ct.category)).reduce((acc, item) => acc.concat(...item), []);
}

/**
 * permitRoutes is the main aside menu structure, that defines categories and links for the aside menu
 * specific paths will be generated _/Category/Route_Title_
 *
 * @category category name, such as _L01_
 * @title the title of the category
 * @path - the url path
 * @layout - one of the layouts to be displayed (admin, page, both)
 * @pageType - is the route a category page or form page or strapi page
 * @showAside - show on admin aside sidebar
 * @menu - show on the menu (top, bottom, both)
 * @article - object with IArticle meta
 * @component - usually it is PageWrapper, which loads the dynamic component from componentPath
 * @componentPath - the path to the dynamic component
 * @priority - the lower the number, the highest is the priority in the menus
 */
let permitRoutes: Partial<IRouteMeta>[] = [
    /**
     * PERMIT CATEGORIES
     */
    // EXAMPLE OF REPRESENTATION IS BELOW
    // {
    //   requiresAuth: true,
    //   title: 'L03 - Leidimas naudoti žvejybos plotą',
    //   path: '/L03/paraiskos/:id',
    //   componentPath: './l03/L03Paraiska',
    //   layout: 'admin',
    //   category: 'L03',
    // },
    // {
    //   requiresAuth: true,
    //   title: 'Paraiška',
    //   path: '/L03/paraiskos',
    //   showAside: true,
    //   componentPath: './l03/L03Paraiska',
    //   layout: 'admin',
    //   category: 'L03',
    // },
    // {
    //   path: '/L03',
    //   category: 'L03',
    //   layout: 'page',
    //   article: {
    //     title: '',
    //     content: `Kategorijos puslapis<br/><a href="/#/L03/paraiskos">Peržiūrėti paraišką</a>`
    //   },
    // },
    {
        requiresAuth: false,
        path: `/ataskaita/l06_2`,
        type: "l06.2",
        title: "Ataskaita apie sugautas žuvis",
        componentPath: `./l06/L06ViesaAtaskaita`,
        layout: `admin`,
        pageType: "form-page",
    },
    {
        requiresAuth: false,
        path: `/leidimo-paieska/l06_2`,
        type: "l06.2",
        title: "Suveskite duomenis L06.2 leidimo paieškai",
        componentPath: `./l06/L06ReportFindForm`,
        layout: `admin`,
        centerContent: true,
        hideWhiteBackground: true,
    },
    {
        requiresAuth: true,
        // title: "L04 Kvotos įrankių konvertavimas",
        path: "/kvotuKonvertavimas/:id",
        componentPath: "./l04/Kvotos/L04Kvota",
        layout: "admin",
        category: "L04",
    },
    ...setCategories(),
];

permitRoutes = permitRoutes.map((route) => {
    return {
        ...route,
        component: PageWrapper,
    } as IRouteMeta;
});

export default permitRoutes;
