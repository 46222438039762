import { TFunction } from "i18next";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { publicRoutes, routes } from "../../../../router";
import { AuthContext, Authorization } from "../../../AuthContext";
import { KeistiAtstovaujamaAsmeni } from "../../../KeistiAtstovaujamaAsmeni";
import { Profilis } from "../../../Profilis";
import { setLanguage } from "./../../../i18n";

interface Props {
  mobileAsideToggle: () => void;
  history?: any;
  locale: string;
  t: TFunction;
}
/**
 * @expandKey unique category key to expand category links
 */
interface State {
  expandKey: string | undefined;
  showPersonChangeDialog: boolean;
  showProfileDialog: boolean;
  allowClose: boolean;
}

class AsideMenu extends React.Component<Props, State> {
  static contextType = AuthContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      expandKey: "",
      showPersonChangeDialog: false,
      showProfileDialog: false,
      allowClose: true
    };
  }

  personChangeDialogToggle(toggle: boolean, e?: React.MouseEvent) {
    this.setState({
      showPersonChangeDialog: toggle
    });
    e?.preventDefault();
  }
  profileDialogToggle(toggle: boolean, e?: React.MouseEvent) {
    this.setState({
      showProfileDialog: toggle,
      allowClose: true
    });
    e?.preventDefault();
  }

  userContactNeedsUpdate(authorization: Authorization) {
    let contactInfo = authorization.atstovaujamasAsmuo?.contactInfo;
    if (
      contactInfo &&
      (contactInfo.email.length == 0 ||
        contactInfo.phone.length == 0 ||
        contactInfo.address.length == 0)
    ) {
      this.setState({
        showProfileDialog: true,
        allowClose: false
      });
    }
  }

  componentDidMount() {
    this.expandWithKey(this.selectedCategory);
  }

  componentWillUnmount() {
    this.setState({});
  }

  expandWithKey(key?: string) {
    if (this.state.expandKey !== key) {
      this.setState({ expandKey: key });
    } else this.setState({ expandKey: "" });
  }

  showCategoryNumber(category?: string) {
    return category !== "pranesimai" && category !== "Admin";
  }

  isSelectedMenu(menuPath: string = "") {
    return [
      window.location.pathname,
      window.location.hash.replace("#", "")
    ].includes(menuPath);
  }

  get selectedCategory() {
    const { hash, pathname } = window.location;
    const path = hash.length ? hash : pathname;
    if (path.length) {
      const route = routes().find((r) => path.includes(r.category as string));
      if (route) return route.category;
    }
    return;
  }

  clickLink(e: React.MouseEvent, path?: string) {
    e.preventDefault();
    this.props.mobileAsideToggle();
    if (path && this.props.history) {
      setTimeout(() => {
        this.props.history.push(path);
      }, 0);
    }
  }

  onLanguageChange(e: React.MouseEvent, lng: "en" | "lt") {
    e.preventDefault();
    setLanguage(lng);
  }

  getCategoryMenus(category: string) {
    return routes().filter(
      (r) =>
        r.showAside && r.pageType !== "category-page" && r.category === category
    );
  }

  render(): ReactNode {
    const isLithuanianLanguage = this.props.locale === "lt";

    return (
      <PerfectScrollbar
        id="kt_aside_menu"
        className="kt-aside-menu kt-scroll"
        data-ktmenu-vertical="1"
        data-ktmenu-scroll="1"
        data-ktmenu-dropdown-timeout="500"
      >
        <div className="kt-menu__container">
          <div>
            <ul className="kt-menu__nav">
              <AuthContext.Consumer>
                {(context) =>
                  !context.authenticated ? (
                    <div className="kt-aside-menu-margin-top" />
                  ) : (
                    <li className="kt-menu__section ">
                      <h4 className="kt-menu__section-text">
                        {this.props.t("nav.manoDuomenys")}
                      </h4>
                      <i className="kt-menu__section-icon flaticon-more-v2"></i>
                    </li>
                  )
                }
              </AuthContext.Consumer>

              {/* {
            links.map(route => (
              <li className="kt-menu__item kt-menu__item--submenu kt-menu__item--open" aria-haspopup="true" data-ktmenu-submenu-toggle="hover" key={route.path}>
                <a onClick={() => this.expandWithKey(route.category)} className="kt-menu__link kt-menu__toggle">
                  <span className="kt-menu__link-icon" style={{ fontWeight: "bold", fontSize: "14px" }}>{route.category}</span>
                </a>
                <div className="kt-menu__submenu " kt-hidden-height="160">
                <ul className="kt-menu__subnav">
                  {
                    route.category &&
                    this.getCategoryMenus(route.category).map(rr => {
                      return <li className={`kt-menu__item${this.isSelectedMenu(rr.path) ? " kt-menu__item--active" : ""}`} aria-haspopup="true" key={rr.path}>
                        {
                          rr.path && <Link to={rr.path} className="kt-menu__link ">
                            <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                            <span className="kt-menu__link-text">{rr.title}</span>
                          </Link>
                        }
                      </li>
                    })
                  }
                </ul>
                </div>
              </li>
            ))
          } */}
            </ul>

            <AuthContext.Consumer>
              {(context) =>
                !context.authenticated ? (
                  <></>
                ) : (
                  <ul className="kt-menu__nav kt-menu_mobile_authenticated_routes_container">
                    {routes()
                      .filter(
                        (r) => r.pageType === "category-page" && r.title !== ""
                      )
                      .map((route, i) => {
                        return !route.role ||
                          context.roles?.find((r) => r === route.role)
                          ? (route.category &&
                              this.getCategoryMenus(route.category).length >
                                0 && (
                                <li
                                  className={`kt-menu__item kt-menu__item--submenu ${
                                    this.state.expandKey === route.category
                                      ? "kt-menu__item--open"
                                      : ""
                                  }`}
                                  aria-haspopup="true"
                                  data-ktmenu-submenu-toggle="hover"
                                  key={route.path}
                                >
                                  <a
                                    onClick={() =>
                                      this.expandWithKey(route.category)
                                    }
                                    className="kt-menu__link kt-menu__toggle"
                                  >
                                    {route.icon && (
                                      <i
                                        className={`kt-menu__link-icon ${route.icon}`}
                                      ></i>
                                    )}
                                    <span className="kt-menu__link-text">
                                      {isLithuanianLanguage
                                        ? route.title
                                        : route.titleEn}
                                    </span>
                                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                                  </a>
                                  <div
                                    className="kt-menu__submenu"
                                    kt-hidden-height="160"
                                  >
                                    <span className="kt-menu__arrow"></span>
                                    <ul className="kt-menu__subnav">
                                      {route.category &&
                                        this.getCategoryMenus(
                                          route.category
                                        ).map((rr) => (
                                          <li
                                            className={`kt-menu__item ${
                                              this.isSelectedMenu(rr.path)
                                                ? " kt-menu__item--active"
                                                : ""
                                            }`}
                                            aria-haspopup="true"
                                            key={"inner_" + rr.path}
                                          >
                                            {rr.path && (
                                              <Link
                                                onClick={(e) =>
                                                  this.clickLink(e, rr.path)
                                                }
                                                to={rr.path}
                                                className="kt-menu__link "
                                              >
                                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                                                  <span></span>
                                                </i>
                                                <span className="kt-menu__link-text">
                                                  {isLithuanianLanguage
                                                    ? rr?.title
                                                    : rr?.titleEn}
                                                </span>
                                              </Link>
                                            )}
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </li>
                              )) ||
                              ((!route.category ||
                                (route.category &&
                                  this.getCategoryMenus(route.category)
                                    .length === 0)) && (
                                <li
                                  className={`kt-menu__item`}
                                  key={route.path}
                                >
                                  <Link
                                    onClick={(e) =>
                                      this.clickLink(e, route.path)
                                    }
                                    to={route.path}
                                    className="kt-menu__link "
                                  >
                                    {route.icon && (
                                      <i
                                        className={`kt-menu__link-icon ${route.icon}`}
                                      >
                                        <span></span>
                                      </i>
                                    )}
                                    <span className="kt-menu__link-text">
                                      {isLithuanianLanguage
                                        ? route?.title
                                        : route?.titleEn}
                                    </span>
                                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                                  </Link>
                                </li>
                              ))
                          : null;
                      })}
                  </ul>
                )
              }
            </AuthContext.Consumer>

            <ul className="kt-menu__nav kt-aside-menu_desktop_hide">
              {publicRoutes.map((route) => {
                return (
                  <li
                    className={`kt-menu__item ${
                      this.isSelectedMenu(route.url)
                        ? " kt-menu__item--active"
                        : ""
                    }`}
                    key={route.url}
                  >
                    <Link
                      onClick={(e) => this.clickLink(e, route.url)}
                      to={route.url}
                      className="kt-menu__link "
                    >
                      <span className="kt-menu__link-text">
                        {isLithuanianLanguage ? route?.title : route?.titleEn}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="kt-aside-menu_desktop_hide">
            <AuthContext.Consumer>
              {(context) =>
                !context.authenticated ? (
                  <ul className="kt-menu__nav">
                    <li className={`kt-menu__item`}>
                      <Link
                        onClick={(e) => this.clickLink(e, "/login")}
                        to="/login"
                        className="kt-menu__link "
                      >
                        <span className="kt-menu__link-text">
                          {this.props.t("home.btn.login")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <>
                    <ul className="kt-menu__nav">
                      <li className={`kt-menu__item`}>
                        <Link
                          onClick={(e) =>
                            this.personChangeDialogToggle(true, e)
                          }
                          to="/"
                          className="kt-menu__link "
                        >
                          <span className="kt-menu__link-text">
                            {this.props.t("nav.keistiAtstovaujamaAsmeni")}
                          </span>
                        </Link>
                      </li>

                      <li className={`kt-menu__item`}>
                        <Link
                          onClick={(e) => this.profileDialogToggle(true, e)}
                          to="/"
                          className="kt-menu__link "
                        >
                          <span className="kt-menu__link-text">
                            {this.props.t("nav.manoProfilis")}
                          </span>
                        </Link>
                      </li>

                      <li className={`kt-menu__item`}>
                        <Link
                          onClick={this.context.logout}
                          to="/"
                          className="kt-menu__link "
                        >
                          <span className="kt-menu__link-text">
                            {this.props.t("nav.atsijungti")}
                          </span>
                        </Link>
                      </li>
                    </ul>
                    <KeistiAtstovaujamaAsmeni
                      callback={() => this.personChangeDialogToggle(false)}
                      show={this.state.showPersonChangeDialog}
                    />
                    {this.state.showProfileDialog && (
                      <Profilis
                        onClose={() => this.profileDialogToggle(false)}
                        show={true}
                        allowCloseBtn={this.state.allowClose}
                      />
                    )}
                  </>
                )
              }
            </AuthContext.Consumer>
            <div className="kt-menu__nav kt-menu__nav_row">
              <Link
                to="&language=en"
                onClick={(e) => this.onLanguageChange(e, "en")}
                className="kt-menu__nav_row_item"
              >
                <img
                  className="kt-menu__nav_row_icon"
                  src={require("./../../../../theme/keen/sass/global/components/media/flags/226-united-states.svg")}
                  alt=""
                />
                <span>English</span>
              </Link>
              <Link
                to="&language=lt"
                onClick={(e) => this.onLanguageChange(e, "lt")}
                className="kt-menu__nav_row_item"
              >
                <img
                  className="kt-menu__nav_row_icon"
                  src={require("./../../../../theme/keen/sass/global/components/media/flags/064-lithuania.svg")}
                  alt=""
                />
                <span>Lietuviškai</span>
              </Link>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    );
  }
}

// export default AsideMenu

export const AsideMenuWrapper = (props: any) => {
  const { t, i18n } = useTranslation();

  return <AsideMenu {...props} locale={i18n.language} t={t} />;
};

// @ts-ignore
export default withRouter(AsideMenuWrapper);
