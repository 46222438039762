import React, { PureComponent, ReactNode } from 'react';
import { routes } from '../../../../router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props {
  locale: string;
  t: TFunction;
}
class FooterBasePC extends PureComponent<Props> {
  isHref(path: string) {
    return path.includes('http://') || path.includes('https://');
  }
  render(): ReactNode {
    return (
      <div
        className='kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop'
        id='kt_footer'
      >
        <div className='kt-container  kt-container--fluid'>
          <div className='kt-footer__copyright'>
            {new Date().getFullYear()}&nbsp;&copy;&nbsp;ALIS
          </div>
          <div className='kt-footer__menu'>
            {routes()
              .filter((r) => r.block === 'footer')
              .map((item) => {
                if (!this.isHref(item.path))
                  return (
                    <Link
                      to={item.path}
                      className='kt-footer__menu-link kt-link'
                      key={item.path}
                    >
                      {this.props.locale === 'lt' ? item.title : item.titleEn}
                    </Link>
                  );
                return (
                  <a
                    href={item.path}
                    className='kt-footer__menu-link kt-link'
                    target='_blank'
                    key={item.path}
                  >
                    {this.props.locale === 'lt' ? item.title : item.titleEn}
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export const FooterBase: React.FC<{}> = () => {
  const { t, i18n } = useTranslation();

  return <FooterBasePC locale={i18n.language} t={t} />;
};

export default FooterBase;
