import { TFunction } from 'i18next';
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

interface Props {
  menu: { title: string, titleEn: string, url:string }[]
  locale: string;
  t: TFunction;
}

class TopbarMenuPC extends React.Component<Props> {
  render(): ReactNode {
    return (
      <div className="kt-header__topbar-item flex-grow-1">
				<div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px" aria-haspopup="true" aria-expanded="false" id="mobileMenuButton">
          <div className="mobile-menu">
            <button className="btn dropdown-toggle" type="button">
              {this.props.t("home.btn.menu")}
            </button>
          </div>
				</div>
				<div className="dropdown-menu dropdown-menu-fit dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-sm" aria-labelledby="mobileMenuButton">
          <ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
            <li className="kt-nav__item">
              <Link to="/permits" className="dropdown-item text-success kt-nav__link">
                <span>{this.props.t("home.btn.orderPermit")}</span>
                <i className="la la-angle-right text-success"></i>
              </Link>
            </li>
            {
              this.props.menu.map(l => (
                <li className="kt-nav__item" key={l.title}>
                  <Link className="dropdown-item kt-nav__link" to={l.url}>{this.props.locale === 'lt' ? l.title : l.titleEn}</Link>
                </li>
              ))
            }
          </ul>
				</div>
			</div>
    )
  }
}

export const TopbarMenu: React.FC<{menu: { title: string, titleEn: string, url: string }[]}> = ({menu}) => {

	const { t, i18n } = useTranslation();
  
	return (
		  <TopbarMenuPC menu={menu} locale={i18n.language} t={t} />
	);
}

export default TopbarMenu
