import React, {useEffect, useState} from "react";
import {Trans} from "react-i18next";
import {useLocation} from "react-router-dom";
import {postbackViisp} from "../../resources/auth";
import {Authorization} from "../AuthContext";
import KTSpinner from "../../components/Thematic/KTSpinner";
import { Alert, Col, Row } from "react-bootstrap";

function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}

export const ViispAuthenticationPostback: React.FC<{ setAuthorization: (authorization: Authorization) => void }> = ({setAuthorization}) => {
    const params = useQuery();
    const ticket = params.get('ticket');
    const imonesKodas = params.get('imonesKodas');

    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        if (ticket) {
            if(imonesKodas){
                postbackViisp(ticket, imonesKodas)

                .then(value => {
                    setAuthorization(value)
                })
                .catch(() => setError('prisijungti.error'));
            } else {
                postbackViisp(ticket)

                .then(value => {
                    setAuthorization(value)
                })
                .catch(() => setError('prisijungti.error'));
            }
        }
            
    }, [ticket]);

    return (
        <div className="text-center">
            <KTSpinner />
            <div style={{margin: "auto"}} className="w-50">
            <Alert variant="info" show={true} className="justify-content-md-center mt-4"><Trans i18nKey="loading.igaliojimuRegistras" /></Alert>
            </div>
        </div>
    );
};
