import React, { useCallback, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Trans, useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { isCompany, isPerson, Profile } from "../resources/types";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import FormGroupComponent from "./form/FormGroupComponent";
import { AlisFormField } from "./form/AlisFormField";
import * as yup from "yup";
import { AlisFormCheckField } from "./form/AlisFormCheckField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setValidationSchemaLocale, yupPhone } from "./common/validation";
import { AuthContext } from "./AuthContext";
import { saveAlisProfile } from "../resources/profiliai";
import "./Profilis.scss";
import { NotificationsContext } from "./NotificationsContext";

library.add(faSpinner);

interface Props {
    onSubmit?: (profilis: Profile) => void;
    onClose: () => void;
    show: boolean;
    allowCloseBtn?: boolean;
}

export const Profilis: React.FC<Props> = ({ show, onSubmit, onClose, allowCloseBtn = true }) => {
    const { t } = useTranslation();
    const auth = useContext(AuthContext);
    const { pushNotifications } = useContext(NotificationsContext);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);

    const validationSchemaProvider = useCallback(() => {
        setValidationSchemaLocale(t);

        const contactInfoValidationSchemaProvider = () => ({
            email: yup.string().required().email(),
            phone: yupPhone(t).required(),
            address: yup
                .string()
                .required()
                .matches(/\b[A-Za-zĄ-Žą-ž]+\b/, t("validation.address"))
                .matches(/\d+/, t("validation.address")),
            infoByPhone: yup.boolean(),
            infoByEmail: yup.boolean(),
        });

        const validationSchema = {
            contactInfo: yup.object(contactInfoValidationSchemaProvider()),
        };
        if (isCompany(auth.atstovaujamasAsmuo)) contactInfoValidationSchemaProvider.position = yup.string().required();

        return yup.object(validationSchema);
    }, [t, auth.atstovaujamasAsmuo]);
    const profileValues = { ...auth.atstovaujamasAsmuo };

    return (
        <Modal show={show} size="lg" onHide={onClose} backdrop={allowCloseBtn ? true : "static"}>
            <Modal.Header>
                <Modal.Title>
                    <Trans i18nKey="profilis.header" />
                </Modal.Title>
                {allowCloseBtn && (
                    <button id="modal-close" type="button" className="close" onClick={onClose}>
                        <span>×</span>
                    </button>
                )}
            </Modal.Header>
            <Formik
                validationSchema={validationSchemaProvider}
                initialValues={profileValues}
                enableReinitialize={false}
                onSubmit={async (values) => {
                    setError(false);
                    setSaving(true);
                    try {
                        const profilis = await saveAlisProfile(values);
                        auth.atstovaujamasAsmuo = profilis;
                        const ai = auth.atstovaujamiAsmenys.findIndex((aa) => aa.code === profilis.code);
                        if (ai >= 0) auth.atstovaujamiAsmenys[ai] = profilis;

                        if (isPerson(profilis) && auth.user) {
                            auth.user.contactInfo = profilis.contactInfo;
                        }
                        if (auth.updateAuthState) auth.updateAuthState(Object.assign({}, auth));
                    } catch (err) {
                        setError(true);
                    }
                    setSaving(false);
                    onClose();
                    pushNotifications({ title: "profilis.saved" });
                }}
            >
                {({ handleSubmit, isValid }) => {
                    return (
                        <>
                            <Form noValidate onSubmit={() => handleSubmit()} className="profile">
                                {isCompany(auth.atstovaujamasAsmuo) && (
                                    <div className="static-data">
                                        <h5 className="mb-5">{t("profilis.atstovaujamasAsmuo")}</h5>
                                        <Form.Label column={false}>{t("profilis.fields.companyName")}</Form.Label>
                                        <div className="h4 mb-4 font-weight-black">{auth.atstovaujamasAsmuo.name}</div>
                                        <Form.Label column={false}>{t("profilis.fields.companyCode")}</Form.Label>
                                        <div className="h4 mb-4 font-weight-black">{auth.atstovaujamasAsmuo.code}</div>
                                    </div>
                                )}
                                {isPerson(auth.atstovaujamasAsmuo) && (
                                    <div className="static-data">
                                        <h5 className="mb-5">{t("profilis.naudotojas")}</h5>
                                        <Form.Label column={false}>{t("profilis.fields.firstName")}</Form.Label>
                                        <div className="h4 mb-4 font-weight-black">{auth.atstovaujamasAsmuo?.firstName}</div>
                                        <Form.Label column={false}>{t("profilis.fields.lastName")}</Form.Label>
                                        <div className="h4 mb-4 font-weight-black">{auth.atstovaujamasAsmuo?.lastName}</div>
                                        <Form.Label column={false}>{t("profilis.fields.code")}</Form.Label>
                                        <div className="h4 mb-4 font-weight-black">{auth.atstovaujamasAsmuo?.code}</div>
                                    </div>
                                )}
                                <div className="input-data">
                                    <h5 className="mb-5">
                                        {isPerson(auth.atstovaujamasAsmuo)
                                            ? t("profilis.naudotojoKontaktai")
                                            : t("profilis.atstovaujamoAsmensKontaktai")}
                                    </h5>
                                    {isCompany(auth.atstovaujamasAsmuo) && (
                                        <>
                                            <FormGroupComponent label="profilis.fields.firstName">
                                                <AlisFormField name="contactInfo.contactFirstName">
                                                    <Form.Control type="text" />
                                                </AlisFormField>
                                            </FormGroupComponent>
                                            <FormGroupComponent label="profilis.fields.contactLastName">
                                                <AlisFormField name="contactInfo.contactLastName">
                                                    <Form.Control type="text" />
                                                </AlisFormField>
                                            </FormGroupComponent>
                                            <FormGroupComponent label="profilis.fields.contactPosition">
                                                <AlisFormField name="contactInfo.position">
                                                    <Form.Control type="text" />
                                                </AlisFormField>
                                            </FormGroupComponent>
                                        </>
                                    )}
                                    <FormGroupComponent label="profilis.fields.email">
                                        <AlisFormField name="contactInfo.email">
                                            <Form.Control type="email" />
                                        </AlisFormField>
                                    </FormGroupComponent>
                                    <FormGroupComponent label="profilis.fields.phone">
                                        <AlisFormField name="contactInfo.phone">
                                            <Form.Control type="text" placeholder="+370XXXXXXXX" />
                                        </AlisFormField>
                                    </FormGroupComponent>
                                    <FormGroupComponent label="profilis.fields.address">
                                        <AlisFormField name="contactInfo.address">
                                            <Form.Control type="text" />
                                        </AlisFormField>
                                    </FormGroupComponent>
                                    <div className="mb-3">Susisiekimo informacija</div>
                                    <AlisFormCheckField
                                        name="contactInfo.infoByPhone"
                                        label={t("profilis.fields.infoByPhone")}
                                        id="profile-info-by-phone"
                                        className="mb-4"
                                    />
                                    <AlisFormCheckField
                                        name="contactInfo.infoByEmail"
                                        label={t("profilis.fields.infoByEmail")}
                                        id="profile-info-by-email"
                                    />
                                </div>
                            </Form>
                            <Modal.Footer>
                                {allowCloseBtn && (
                                    <Button className="m-1" variant="outline-primary" disabled={saving} onClick={onClose}>
                                        {saving && <FontAwesomeIcon icon="spinner" spin />}
                                        {t("common.button.cancel")}
                                    </Button>
                                )}
                                <Button className="m-1" variant="primary" disabled={saving} type="button" onClick={() => handleSubmit()}>
                                    {saving && <FontAwesomeIcon icon="spinner" spin />}
                                    {t("common.button.save")}
                                </Button>
                            </Modal.Footer>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};
