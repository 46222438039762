import StrapiSDK from './StrapiSDK';
import { IPermit, IPermitDynamic } from './types';
import permitsStatic from '../view/permitsStatic';

export const PERMITS_ENDPOINT = `permits`; // only return faq tags with questions

let _permits: IPermit[] = [];

export class Permits {
  // !!!!!!!!!!!!!!!
  // We wrap getEntries event, because strapi does not allow to use enum values with "." symbol
  // permits have permitId field, which is a enum value in strapi DB, like "L05_1", but we need
  // "L05.1", so we rewrite "-" with "." everytime we call StrapiSDK.getEntries
  static replacePermitId(permit: IPermitDynamic) {
    if (permit.permitId) permit.permitId = permit.permitId.replace('_', '.');
    return permit;
  }
  static async getPermitEntries(locale: string) {
    const localizedPermits = (await StrapiSDK.getEntries(
      `${PERMITS_ENDPOINT}?_sort=permitId:ASC&_locale=${locale}`
    )) as IPermitDynamic[];
    const defaultPermits = (await StrapiSDK.getEntries(
      `${PERMITS_ENDPOINT}?_sort=permitId:ASC`
    )) as IPermitDynamic[];
    let permits = undefined;
    localizedPermits.length > 0
      ? (permits = localizedPermits)
      : (permits = defaultPermits);
    return permits.map((p) => this.replacePermitId(p)) as IPermit[];
  }

  static async getPermitEntriesByPermitId(id: string, locale: string) {
    const localizedPermits = (await StrapiSDK.getEntries(
      `${PERMITS_ENDPOINT}?permitId=${id.replace('.', '_')}&_locale=${locale}`
    )) as IPermitDynamic[];
    const defaultPermits = (await StrapiSDK.getEntries(
      `${PERMITS_ENDPOINT}?permitId=${id.replace('.', '_')}`
    )) as IPermitDynamic[];
    let permits = null;
    localizedPermits.length > 0
      ? (permits = localizedPermits)
      : (permits = defaultPermits);
    return permits.map((p) => this.replacePermitId(p)) as IPermit[];
  }

  static async getPermits(locale: string) {
    let permits: IPermit[] = _permits;
    //if (_permits.length === 0) {
    permits = await this.getPermitEntries(locale);
    //}
    _permits = permits.map((p) => (p = { ...p, ...permitsStatic[p.permitId] }));
    return _permits;
  }
  static async getPermitByPermitId(permitId: string, locale: string) {
    let permits = await this.getPermitEntriesByPermitId(permitId, locale);
    permits = permits.map((p) => (p = { ...p, ...permitsStatic[p.permitId] }));
    if (permits.length) return permits[0];
    return undefined;
  }
  static async getWarningProperties() {
    return (await StrapiSDK.getEntries(`warnings`)) as {
      warningTitle?: string;
      showWarning?: boolean | false;
    };
  }
}
