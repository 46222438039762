import React, { ReactNode } from "react";
import LazyLoadModule from "../components/LazyLoadModule";
import { titleByRoute } from "../router";
import DefaultLayout from "./Layout/DefaultLayout";
import { renderRoutes } from "react-router-config";
import { RouteComponentProps } from "react-router-dom";
import { AuthContext } from "./AuthContext";

interface Props extends RouteComponentProps {
    route?: any;
}

class PageWrapper extends React.Component<Props> {
    static contextType = AuthContext;
    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        document.title = "ALIS";
        // const category = routes().find(r => r.category === this.props.route.category)
        // if (category && category.title) document.title += ' - ' + category.title
        // if (this.props.route.title) document.title += (category && category.title === this.props.route.title) ? '' : ' - ' + this.props.route.title
        // else document.title += ' - Aplinkosaugos leidimų išdavimo sistema'
        if (this.props.route) {
            const routeTitle = titleByRoute(this.props.route);
            document.title += routeTitle ? " - " + routeTitle : " - Aplinkosaugos leidimų išdavimo sistema";
        }
    }

    module(componentPath: string) {
        const { type, id } = this.props.match.params as any;
        if (type) {
            const t = (type as string).replace("_1", "").replace("_2", "").replace("_3", "");
            componentPath = componentPath.replace(":type", t).replace(":type", t.toUpperCase());
        }
        componentPath = componentPath.replace(":id", id);
        return import(componentPath + "");
    }

    showRoute() {
        return !this.props.route.requiresAuth || (this.props.route.requiresAuth && this.context.authenticated);
    }

    render(): ReactNode {
        const { params } = this.props.match as any;
        if (this.props.route && this.props.route.type) params.type = this.props.route.type;
        return (
            <DefaultLayout layout={this.props.route.layout} route={this.props.route}>
                {(this.props && this.props.route && this.props.route.componentPath && this.showRoute() && (
                    <LazyLoadModule resolve={() => this.module(this.props.route.componentPath)} params={params}>
                        {this.props.children}
                    </LazyLoadModule>
                )) || <>{this.props.children}</>}
                {this.props.route && renderRoutes(this.props.route.routes)}
            </DefaultLayout>
        );
    }
}

export default PageWrapper;
