import { apiGet, textPayloadExtractor, apiPost } from './api';
import { CompanyContactInfo, ContactInfo, Profile } from './types';
import { Authorization } from '../view/AuthContext';
import { toStringDto, toNumberDto, fromStringDto } from './dto';
import { UserData, toProfilisFromUserData } from './profiliai';

export const ROLE_L03 = 'ROLE_L03';

export interface JwtAuthenticationResponseAtstovaujamasAsmuo {
  asmensKodas?: string;
  vardas?: string;
  pavarde?: string;

  imonesKodas?: string;
  imonesPavadinimas?: string;
  epastas?: string;
  tel?: string;
  infoPastu?: boolean;
  infoSms?: boolean;
  kontaktinioAsmensVardas?: string;
  kontaktinioAsmensPavarde?: string;
  pareigos?: string;
  adresas?: any;
  defaultProfileIndex?: number;
}

export function toAuthorization(payload: UserData): Authorization {
  const auth = new Authorization();

  //resolve atstovaujami asmenys
  auth.atstovaujamiAsmenys = payload.atstovaujamiAsmenys.map(
    toProfilisFromUserData
  );

  let contactInfo;
  for (const atstovaujamasAsmuo of auth.atstovaujamiAsmenys) {
    if (atstovaujamasAsmuo.code === payload.asmensKodas) {
      contactInfo = atstovaujamasAsmuo.contactInfo;
    }
  }

  auth.user = {
    firstName: payload.name,
    lastName: payload.surname,
    code: payload.asmensKodas,
    contactInfo: contactInfo,
  };

  auth.roles = payload.authorities
    ? payload.authorities.map((a: any) => a.authority)
    : [];
  auth.authenticated = true;
  auth.defaultProfileIndex = payload.defaultProfileIndex;
  auth.atstovaujamasAsmuo = auth.atstovaujamiAsmenys
    ? auth.atstovaujamiAsmenys[auth.defaultProfileIndex]
    : undefined;

  return auth;
}

export function toContactInfo(
  dto: Profile | undefined
): ContactInfo | CompanyContactInfo {
  if (dto) return dto.contactInfo as ContactInfo;
  else throw 'no contact info';
}

export function fromAtstovaujamoAsmensProfilisDto2(
  dto: any
): ContactInfo | CompanyContactInfo {
  return {
    address: dto.adresas ? fromStringDto(dto.adresas.adresasTekstas) : '',
    email: fromStringDto(dto.epastas),
    phone: fromStringDto(dto.tel),
    infoByEmail: !!dto.infoEpastu,
    infoByPhone: !!dto.infoSms,
    position: fromStringDto(dto.pareigos),
    contact:
      dto.vardas || dto.pavarde
        ? {
            firstName: fromStringDto(dto.vardas),
            lastName: fromStringDto(dto.pavarde),
          }
        : {
            firstName: '',
            lastName: '',
          },
  };
}

export function readViispTicket(): Promise<string> {
  return apiGet('/auth/viisp/generate-ticket', {}, textPayloadExtractor);
}

export function postbackViisp(
  ticket: string,
  imonesKodas?: string
): Promise<Authorization> {
  return apiGet('/auth/viisp/postback', { ticket, imonesKodas }).then((value) =>
    Promise.resolve(toAuthorization(value))
  );
}

//Test login
export interface AsmuoValues {
  asmensKodas?: string;
  vardas?: string;
  pavarde?: string;
  viispId?: string;
  id?: string;

  imonesKodas?: string;
  imonesPavadinimas?: string;
}

export interface AsmuoValuesDto {
  asmensKodas?: string;
  vardas?: string;
  pavarde?: string;
  viispId?: string;
  id?: number;

  imonesKodas?: string;
  imonesPavadinimas?: string;
}

function toAsmuoDto(values: AsmuoValues): Partial<AsmuoValuesDto> {
  return {
    asmensKodas: toStringDto(values.asmensKodas),
    vardas: toStringDto(values.vardas),
    pavarde: toStringDto(values.pavarde),
    viispId: toStringDto(values.viispId),
    id: toNumberDto(values.id),

    imonesKodas: toStringDto(values.imonesKodas),
    imonesPavadinimas: toStringDto(values.imonesPavadinimas),
  };
}

export function postViisp(values: AsmuoValues): Promise<AsmuoValues> {
  return apiPost('/auth/viisp/dummy-login', {}, toAsmuoDto(values));
}
