import * as React from "react";
import { RouteChildrenProps } from "react-router-dom";
import KTSpinner from "./Thematic/KTSpinner";

interface Props {
  resolve: () => any // Promise<React.ComponentClass>
  params?: { id: string, type?: string }
}

interface State {
  Module: any // React.ComponentClass | null
}

export default class LazyLoadModule extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      Module: null
    } as State;
  }

  // after the initial render, wait for module to load
  async componentDidMount() {
    const { resolve } = this.props;
    const re = resolve
    const Module = await resolve() as any;
    this.setState({ Module: Module.default });
  }

  // Fixes possible memory leak issues, leave this code untouched!
  componentWillUnmount() {
    this.setState({ Module: null });
    this.setState ({})
  }

  render() {
    const { Module } = this.state;
    let _id:string | undefined
    let _type:string | undefined
    const match = this.props.children ? (this.props.children as RouteChildrenProps).match : undefined
    if (match) {
      const { id, type } = match.params as any
      _id = id
      _type = type
    }
    if (!_id && this.props.params && this.props.params.id) _id = this.props.params.id
    if (!_type && this.props.params && this.props.params.type) _type = this.props.params.type
    if (_type) _type = _type.toUpperCase()
    if (Module) return <Module id={_id} type={_type}>
      {this.props.children}
    </Module>;
    else return <div className='min-vh-full d-flex align-items-center justify-content-center'>
      <KTSpinner />
    </div>
  }
}
