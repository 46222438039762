import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory, useLocation } from "react-router-dom";
import { ViispAuthenticationStart } from "./ViispAuthenticationStart";
import { ViispAuthenticationPostback } from "./ViispAuthenticationPostback";
import { KeistiAtstovaujamaAsmeni } from "../KeistiAtstovaujamaAsmeni";
import { Profile } from "../../resources/types";
import { checkAlisProfile, readAlisProfile } from "../../resources/profiliai";
import { ErrorContext } from "../ErrorContext";
import { Profilis } from "../Profilis";
import { AuthContext, Authorization } from "../AuthContext";
import { TestinisPrisijungti } from "../TestinisPrisijungti/TestinisPrisijungti";
import ContentBase from "../Layout/Partials/Content/ContentBase";
import { Card } from "react-bootstrap";

export const Login: React.FC = () => {
    const { setError } = useContext(ErrorContext);
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();
    // const {from} = location.state || {from: {pathname: "/"}};

    const { atstovaujamiAsmenys, atstovaujamasAsmuo } = useContext(AuthContext);
    const auth = useContext(AuthContext);
    const userAuthorization = useContext(AuthContext);
    // const [userAuthorization, setUserAuthorization] = useState<Authorization>();
    // const [atstovaujamasAsmuo, setAtstovaujamasAsmuo] = useState<AtstovaujamasAsmuo>();
    const [userProfileValid, setUserProfileValid] = useState(false);
    const [profilis, setProfilis] = useState<Profile>();

    const setAuthorizationCallback = useCallback((authorization: Authorization) => {
        if (atstovaujamiAsmenys?.length === 1) {
            authorization.atstovaujamasAsmuo = atstovaujamiAsmenys[0];
        }
        userAuthorization.updateAuth(authorization);
        history.push("/");
    }, []);

    useEffect(() => {
        if (userAuthorization && atstovaujamasAsmuo) {
            checkAlisProfile(atstovaujamasAsmuo.code)
                .then(setUserProfileValid)
                .then(() => readAlisProfile(atstovaujamasAsmuo.code))
                .catch(setError);
        }
    }, [userAuthorization, atstovaujamasAsmuo, setError]);

    // const setAuthorizationCallback = useCallback((authorization: Authorization) => {
    // 	setAuthorization(authorization);\
    // 	history.replace(from);
    // }, [setAuthorization, from, history]);

    // useEffect(() => {
    // 	if (userAuthorization && atstovaujamasAsmuo && userProfileValid && profilis) {
    // 		setAuthorizationCallback({...userAuthorization, atstovaujamasAsmuo, profilis});
    // 	}
    // }, [userAuthorization, atstovaujamasAsmuo, profilis, userProfileValid, setAuthorizationCallback]);

    if (!userAuthorization.authenticated && location.pathname.endsWith("/viisp")) {
        return <ViispAuthenticationPostback setAuthorization={setAuthorizationCallback} />;
    }

    if (userAuthorization.authenticated && !userAuthorization.atstovaujamasAsmuo && userAuthorization.atstovaujamiAsmenys) {
        return <KeistiAtstovaujamaAsmeni />;
    }

    if (userAuthorization.authenticated && atstovaujamasAsmuo) {
        if (profilis && !userProfileValid) {
            return (
                <Profilis
                    show={true}
                    onSubmit={(profilis) => {
                        auth.atstovaujamasAsmuo = profilis;
                        setUserProfileValid(true);
                    }}
                    onClose={() => {
                        setProfilis(undefined);
                        setUserProfileValid(false);
                    }}
                />
            );
        }

        // wait for profile info or redirect
        return null;
    }

    return (
        <ContentBase title="Prisijunkite">
            {userAuthorization.authenticated && !atstovaujamasAsmuo && <KeistiAtstovaujamaAsmeni />}
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            {t("prisijungti.description")}
                            <ViispAuthenticationStart />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {process.env.REACT_APP_DEBUG === "true" && (
                <Row>
                    <Col>
                        <TestinisPrisijungti />
                    </Col>
                </Row>
            )}
        </ContentBase>
    );
};

export default Login;
