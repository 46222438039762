
import React, { PureComponent, ReactNode } from 'react'
import DropdownUserSolid from '../../Topbar/Dropdown/DropdownUserSolid'
import { AuthContext, Authorization } from '../../../../AuthContext'

class TopbarUser extends PureComponent {
	getUsersTitle(context: Authorization) {
	// 	{
	// 		context?.atstovaujamasAsmuo && context?.atstovaujamasAsmuo?.code !== context?.user?.code ?
	// 				t('nav.atstovauja', {
	// 						atstovauja: isPersonProfile(context?.atstovaujamasAsmuo)
	// 								? "asmuo"+t('common.person.name', context?.atstovaujamasAsmuo) : "imone|" + context?.atstovaujamasAsmuo?.name,
	// 						atstovas: t('common.person.name', context?.user)
	// 				}) : t('common.person.name', context?.atstovaujamasAsmuo)
	// }
		if (context.atstovaujamasAsmuo && context.atstovaujamasAsmuo?.name) {
			return context.atstovaujamasAsmuo.name
		}
		let name = 'Vartotojas'
		if (context.atstovaujamasAsmuo?.firstName) name = context.atstovaujamasAsmuo.firstName
		if (context.atstovaujamasAsmuo?.lastName) name += ' ' + context.atstovaujamasAsmuo.lastName
		return name
	}

	render(): ReactNode {
		return (
			<div className="kt-header__topbar-item kt-header__topbar-item--user">
				<div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px">
					<div className="kt-header__topbar-user kt-rounded-">
						<AuthContext.Consumer>
							{(context) => (
								<span className="kt-header__topbar-username kt-hidden-mobile">{this.getUsersTitle(context)}</span>
							)}
						</AuthContext.Consumer>
						<i className="flaticon2-user text-success"></i>
						<span className="kt-badge kt-badge--username kt-badge--lg kt-badge--brand kt-hidden kt-badge--bold">S</span>
					</div>
				</div>
				<div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
					<AuthContext.Consumer>
						{(context) => (
							<DropdownUserSolid context={context} />
						)}
					</AuthContext.Consumer>
				</div>
			</div>
		)
	}
}

export default TopbarUser
