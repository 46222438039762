import React, { ReactNode } from "react";
import { IRouteMeta } from "../../permitRoutes";
import { Permits } from "../../StrapiService";
import { LocaleContext } from "../LocaleContext";
import AsideBase from "./Partials/Aside/AsideBase";
import ContentBase from "./Partials/Content/ContentBase";
import FooterBase from "./Partials/Footer/FooterBase";
import HeaderBase from "./Partials/Header/HeaderBase";
import HeaderBaseMobile from "./Partials/Header/HeaderBaseMobile";
interface Props {
  route?: IRouteMeta;
}

interface State {
  warning: {
    warningTitle?: string;
    showWarning?: boolean;
  };
  mobileAsideShow: boolean;
  mobileToolbarShow: boolean;
}
class PageLayout extends React.Component<Props, State> {
  /*  static contextType = AuthContext
  get authenticated() {
    return this.context.authenticated
  } */
  constructor(props: Props) {
    super(props);
    this.state = {
      warning: {
        warningTitle: "",
        showWarning: false
      },
      mobileAsideShow: false,
      mobileToolbarShow: false
    };
  }
  static contextType = LocaleContext;

  resolveTitle() {
    let title = this.props.route?.title;

    this.context === "en" && this.props.route?.titleEn
      ? (title = this.props.route?.titleEn)
      : (title = this.props.route?.title);

    return title;
  }

  handleWindowResize() {
    this.setState({
      mobileToolbarShow: false,
      mobileAsideShow: false
    });
    document.body.classList.remove("kt-aside--on");
    document.body.classList.remove("kt-header__topbar--mobile-on");
  }

  mobileAsideToggle() {
    this.setState(
      {
        mobileAsideShow: !this.state.mobileAsideShow
      },
      () => {
        this.state.mobileAsideShow
          ? document.body.classList.add("kt-aside--on")
          : document.body.classList.remove("kt-aside--on");
      }
    );
  }

  mobileToolbarToggle() {
    this.setState(
      {
        mobileToolbarShow: !this.state.mobileToolbarShow
      },
      () => {
        this.state.mobileToolbarShow
          ? document.body.classList.add("kt-header__topbar--mobile-on")
          : document.body.classList.remove("kt-header__topbar--mobile-on");
      }
    );
  }

  async componentDidMount() {
    this.setState({
      warning: await Permits.getWarningProperties()
    });
    window.addEventListener("resize", () => this.handleWindowResize());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.handleWindowResize());
  }

  render(): ReactNode {
    const title = this.resolveTitle(); //this.props.route?.title
    const icon = this.props.route?.icon;
    const isStrapiPage = this.props.route?.isStrapiPage && true;
    const body = document.body;

    body.classList.remove("kt-aside--enabled");
    return (
      <div
        className={`default-layout${
          this.props.route?.centerContent ? " centered" : ""
        }`}
      >
        <HeaderBaseMobile
          mobileToolbarToggle={() => this.mobileToolbarToggle()}
          mobileAsideToggle={() => this.mobileAsideToggle()}
        />
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <AsideBase
            mobileAsideShow={this.state.mobileAsideShow}
            mobileAsideToggle={() => this.mobileAsideToggle()}
          />
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            <HeaderBase
              children={this.props.children}
              showWarning={this.state.warning.showWarning}
              warningTitle={this.state.warning.warningTitle}
            />
            <ContentBase
              title={title && !isStrapiPage ? title : ""}
              icon={icon && !isStrapiPage ? icon : ""}
              children={this.props.children}
              pageType={this.props.route?.pageType}
              hideWhiteBackground={this.props.route?.hideWhiteBackground}
            />
            <FooterBase />
          </div>
        </div>
      </div>
    );
  }
}

export default PageLayout;
