import StrapiSDK from './StrapiSDK'
import { IEditorImage } from './types'

export const UPLOADS_ENDPOINT = `upload/files/` // only return faq tags with questions

export class Uploads {
  static async getImageSrcSetById(id: string) {
    return await StrapiSDK.getEntries(UPLOADS_ENDPOINT + id) as IEditorImage[]
  }
  static getImageSrcSetWithUrl(url: string) {
    return StrapiSDK.strapiUrl + url
  }
}