import React from 'react'

function KTSpinner() {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <div className="kt-spinner kt-spinner--lg kt-spinner--brand">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default KTSpinner
