/**
 * @orderUrl order permit url
 * @downloadUrl button for download permit (on some permits only)
 * @reportUrl button for report (on some permits only [L06.2])
 * @imageUrl image on the permit page
 * @iconUrl icon for the permit
 * @indexImageUrl image for mostly used index permit blocks
 * @indexColor optional color, only for most used permits
 * @indexIconColor optional color, only for most used permits (light, grey, dark)
 * @mostlyUsed is permit mostly used, then it gets EnumPermitCategory value, default _undefined_
 */
export interface IPermitStatic {
    orderUrl: string;
    downloadUrl?: string;
    reportUrl?: string;
    iconUrl: string;
    imageUrl?: string;
    indexImageUrl?: string;
    indexBgColor?: string;
    indexAuthBgColor?: string;
    indexIconColor?: "light" | "light-transparent" | "dark" | "dark-transparent";
    indexAuthIconColor?: "light" | "dark";
    indexAuthDescColor?: "light" | "dark";
    statisticsUrl: string;
    statisticsButtonTitle: string;
    statisticsButtonTitleEn?: string;
    usedMostly?: EnumPermitCategory;
    usedMostlyPriority?: number; // the higher the priority, the higher in the 'used mostly' list is the permit
}

// IMPORTANT: this enum is aligned with categories on strapi CMS, so those values should be the same here and in DB
export enum EnumPermitCategory {
    fishing = "fishing",
    forests = "forests",
    pollution = "pollution",
    resources = "resources",
    other = "other",
}

export type TPermitCategoryKeys = keyof typeof EnumPermitCategory;
export type IPermitCategories = {
    [key in TPermitCategoryKeys]: string;
};

export const permitsCategories: IPermitCategories = {
    fishing: "žvejyba",
    forests: "miškų kirtimas",
    pollution: "tarša",
    resources: "gamtos ištekliai",
    other: "kita",
};

export const permitsCategoriesEn: IPermitCategories = {
    fishing: "fishing",
    forests: "forest cutting",
    pollution: "pollution",
    resources: "natural resources",
    other: "other",
};

export const permitBreadcrumbs = (locale: string) => {
    const bKeys = Object.keys(permitsCategories);
    const bValues = locale === "lt" ? Object.values(permitsCategories) : Object.keys(permitsCategoriesEn);
    return bKeys.map((b, i) => {
        return { title: bValues[i], url: b };
    });
};

export const permitTitleByType = (permitType: TPermitCategoryKeys) => {
    const bKeys = Object.keys(permitsCategories);
    const title = Object.values(permitsCategories)[bKeys.findIndex((p) => p === permitType)];
    return title;
};

export interface IPermitsListStatic {
    // key is permit unique number, this will be used to bind permits data on strapi cms
    [key: string]: IPermitStatic;
}

const permitsStatic: IPermitsListStatic = {
    L01: {
        orderUrl: "/paraiskos/l01",
        iconUrl: "/assets/svgs/truck.svg",
        statisticsUrl: "/public-info/L01",
        statisticsButtonTitle: "Gyvūnų, augalų ar grybų įvežimo/išvežimo leidimų duomenys",
        statisticsButtonTitleEn: "Gyvūnų, augalų ar grybų įvežimo/išvežimo leidimų duomenys en",
    },
    L02: {
        orderUrl: "/paraiskos/l02",
        iconUrl: "/assets/svgs/basket.svg",
        imageUrl: "",
        statisticsUrl: "/public-info/L02",
        statisticsButtonTitle: "Prekybos gyvūnais ar augalais leidimų duomenys",
        statisticsButtonTitleEn: "Prekybos gyvūnais ar augalais leidimų duomenys",
    },
    L03: {
        orderUrl: "/paraiskos/l03",
        iconUrl: "/assets/svgs/water-hook.svg",
        indexImageUrl: "/assets/picts/permits/L03_index.jpg",
        indexBgColor: "#E3E8EB",
        statisticsUrl: "/public-info/l03",
        usedMostly: EnumPermitCategory.fishing,
        usedMostlyPriority: -1,
        statisticsButtonTitle: "Leidimų naudoti žvejybos plotą duomenys",
        statisticsButtonTitleEn: "Leidimų naudoti žvejybos plotą duomenys",
    },
    L04: {
        orderUrl: "/paraiskos/l04",
        iconUrl: "/assets/svgs/ship.svg",
        indexBgColor: "#D7E5D8",
        indexIconColor: "light",
        indexAuthIconColor: "light",
        indexAuthDescColor: "dark",
        indexAuthBgColor: "#FFD585",
        statisticsUrl: "/public-info/l04",
        statisticsButtonTitle: "Verslinės žvejybos leidimų duomenys",
        statisticsButtonTitleEn: "Verslinės žvejybos leidimų duomenys",
    },
    "L05.1": {
        orderUrl: "/paraiskos/l05_1",
        iconUrl: "/assets/svgs/fish.svg",
        indexIconColor: "light",
        indexBgColor: "#D7E5D8",
        indexAuthIconColor: "light",
        indexAuthDescColor: "dark",
        indexAuthBgColor: "#FFD585",
        indexImageUrl: "/assets/picts/permits/L05_1_index.jpg",
        usedMostly: EnumPermitCategory.fishing,
        statisticsUrl: "/public-info/L05",
        downloadUrl: "/atsisiusti/l05",
        statisticsButtonTitle: "Mėgėjų žvejybos leidimų duomenys",
        statisticsButtonTitleEn: "Angler's permits data",
    },
    "L05.2": {
        orderUrl: "/paraiskos/l05_2",
        indexBgColor: "#e4dbcc",
        indexAuthDescColor: "light",
        indexAuthIconColor: "light",
        indexAuthBgColor: "#79BBF8",
        iconUrl: "/assets/svgs/fish.svg",
        //imageUrl: "/assets/picts/permits/L05_2.jpg",
        indexImageUrl: "/assets/picts/permits/L05_2_index.jpg",
        usedMostly: EnumPermitCategory.fishing,
        downloadUrl: "/atsisiusti/l05",
        statisticsUrl: "/public-info/L05",
        statisticsButtonTitle: "Mėgėjų žvejybos leidimų duomenys",
        statisticsButtonTitleEn: "Angler's permits data",
    },
    "L06.1": {
        orderUrl: "/paraiskos/l06_1",
        iconUrl: "/assets/svgs/fishing-id.svg",
        indexBgColor: "#E6F5F8",
        indexAuthDescColor: "light",
        indexAuthIconColor: "light",
        indexAuthBgColor: "#9BD8A8",
        indexImageUrl: "/assets/picts/permits/L06_1_index.jpg",
        usedMostly: EnumPermitCategory.fishing,
        statisticsUrl: "/public-info/l06",
        statisticsButtonTitle: "Išduotų žvejo mėgėjo kortelių duomenys",
        statisticsButtonTitleEn: "Data of issued fisherman's amateur cards",
        downloadUrl: "/atsisiusti/l06",
    },
    "L06.2": {
        orderUrl: "/paraiskos/l06_2",
        iconUrl: "/assets/svgs/fishing-id.svg",
        indexBgColor: "#E1E4E9",
        usedMostly: EnumPermitCategory.fishing,
        indexImageUrl: "/assets/picts/permits/L06_2_index.jpg",
        statisticsUrl: "/public-info/l06",
        statisticsButtonTitle: "Išduotų žvejo mėgėjo kortelių duomenys",
        statisticsButtonTitleEn: "Data of issued fisherman's amateur cards",
        reportUrl: "/leidimo-paieska/l06_2",
        downloadUrl: "/atsisiusti/l06",
    },
    L07: {
        orderUrl: "/paraiskos/l07",
        iconUrl: "/assets/svgs/flask-and-fish.svg",
        statisticsUrl: "/public-info/L07",
        statisticsButtonTitle: "Specialiosios žvejybos leidimų duomenys",
        statisticsButtonTitleEn: "Specialiosios žvejybos leidimų duomenys",
    },
    "L08.1": {
        orderUrl: "/paraiskos/l08_1",
        iconUrl: "/assets/svgs/tree.svg",
        indexImageUrl: "/assets/picts/permits/L08_1_index.jpg",
        usedMostly: EnumPermitCategory.forests,
        statisticsUrl: "/public-info/L08",
        statisticsButtonTitle: "Leidimų kirsti mišką duomenys",
        statisticsButtonTitleEn: "Leidimų kirsti mišką duomenys",
    },
    "L08.2": {
        orderUrl: "/paraiskos/l08_2",
        iconUrl: "/assets/svgs/tree.svg",
        statisticsUrl: "/public-info/L08",
        statisticsButtonTitle: "Leidimų kirsti mišką duomenys",
        statisticsButtonTitleEn: "Leidimų kirsti mišką duomenys",
    },
    "L08.3": {
        orderUrl: "/paraiskos/l08_3",
        iconUrl: "/assets/svgs/tree.svg",
        statisticsUrl: "/public-info/L08",
        statisticsButtonTitle: "Leidimų kirsti mišką duomenys",
        statisticsButtonTitleEn: "Leidimų kirsti mišką duomenys",
    },
    L09: {
        orderUrl: "/paraiskos/l09",
        iconUrl: "/assets/svgs/no-pollution.svg",
        statisticsUrl: "/public-info/L09",
        statisticsButtonTitle: "Taršos integruotos prevencijos ir kontrolės (TIPK) leidimų duomenys",
        statisticsButtonTitleEn: "Taršos integruotos prevencijos ir kontrolės (TIPK) leidimų duomenys",
    },
    L10: {
        orderUrl: "/paraiskos/l10",
        iconUrl: "/assets/svgs/factory.svg",
        statisticsUrl: "/public-info/L10",
        statisticsButtonTitle: "Taršos leidimų duomenys",
        statisticsButtonTitleEn: "Taršos leidimų duomenys",
    },
    L11: {
        orderUrl: "/paraiskos/l11",
        iconUrl: "/assets/svgs/shovel.svg",
        statisticsUrl: "/public-info/l11",
        statisticsButtonTitle: "Leidimų tirti žemės gelmes duomenys",
        statisticsButtonTitleEn: "Leidimų tirti žemės gelmes duomenys",
    },
    L12: {
        orderUrl: "/paraiskos/l12",
        iconUrl: "/assets/svgs/diamond.svg",
        statisticsUrl: "/public-info/L12",
        statisticsButtonTitle: "Leidimų naudoti žemės gelmių išteklius ir ertmes duomenys",
        statisticsButtonTitleEn: "Leidimų naudoti žemės gelmių išteklius ir ertmes duomenys",
    },
    L13: {
        orderUrl: "/paraiskos/l13",
        iconUrl: "/assets/svgs/pollution.svg",
        statisticsUrl: "/public-info/L13",
        statisticsButtonTitle: "Pavojingųjų atliekų tvarkymo licencijų duomenys",
        statisticsButtonTitleEn: "Pavojingųjų atliekų tvarkymo licencijų duomenys",
    },
    "L14.1": {
        orderUrl: "/paraiskos/l14_1",
        iconUrl: "/assets/svgs/flask-and-fish.svg",
        statisticsUrl: "",
        statisticsButtonTitle: "",
    },
    "L14.2": {
        orderUrl: "/paraiskos/l14_2",
        iconUrl: "/assets/svgs/flask-and-fish.svg",
        statisticsUrl: "",
        statisticsButtonTitle: "",
    },
    "L16.1": {
        orderUrl: "/paraiskos/l16_1",
        iconUrl: "/assets/svgs/snow-blowing.svg",
        statisticsUrl: "",
        statisticsButtonTitle: ""
    },
    "L16.2": {
        orderUrl: "/paraiskos/l16_2",
        iconUrl: "/assets/svgs/snow-blowing.svg",
        statisticsUrl: "",
        statisticsButtonTitle: ""
    },
    "L16.3": {
        orderUrl: "/paraiskos/l16_3",
        iconUrl: "/assets/svgs/snow-blowing.svg",
        statisticsUrl: "",
        statisticsButtonTitle: ""
    }
};

export default permitsStatic;
