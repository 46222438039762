import React from "react";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface Props {
	label?: string,
	translatedLabel?: string;
	labelSize?: number;
	fieldSize?: number;
	className?: string;
}

const FormGroupComponent: React.FC<Props> = ({label, translatedLabel, children, labelSize = 4, fieldSize = (12 - labelSize), className}) => {
	const {t} = useTranslation();

	return (
		<Form.Group as={Row} className={className}>
			<Form.Label column sm={labelSize}>{label ? t(label) : ''}{translatedLabel}</Form.Label>
			<Col sm={fieldSize}>
				{children}
			</Col>
		</Form.Group>
	);
};

export default FormGroupComponent;