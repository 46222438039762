import { TFunction } from "i18next";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  mobileAsideToggle: () => void;
  mobileToolbarToggle: () => void;
  t: TFunction;
}
interface State {
  isMenuActive: boolean;
}

class HeaderBaseMobile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isMenuActive: false
    };
  }

  render(): ReactNode {
    return (
      <div
        id="kt_header_mobile"
        className="kt-header-mobile  kt-header-mobile--fixed  kt-header__topbar-item "
      >
        <div className="kt-header-mobile__logo">
          <Link to="/">
            <img
              alt="Logo"
              src={`${process.env.PUBLIC_URL}/assets/picts/alis-public.svg`}
            />
          </Link>
        </div>
        <div className="kt-header-row">
          <Link to="/permits">
            <div className="btn kt-button">
              {this.props.t("home.btn.orderPermit")}
              <i className="kt-menu__hor-arrow la la-angle-right"></i>
            </div>
          </Link>

          <div className="kt-header-mobile__toolbar">
            <button
              onClick={this.props.mobileAsideToggle}
              className={`kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left ${
                this.state.isMenuActive
                  ? "kt-header-mobile__toolbar-toggler--active"
                  : ""
              }`}
              id="kt_aside_mobile_toggler"
            >
              <span></span>
            </button>
          </div>

          {/* <button className="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler"><span></span></button> */}
        </div>
      </div>
    );
  }
}

export const HeaderMenu: React.FC<{
  mobileToolbarToggle: any;
  mobileAsideToggle: any;
}> = ({ mobileToolbarToggle, mobileAsideToggle }) => {
  const { t } = useTranslation();

  return (
    <HeaderBaseMobile
      mobileToolbarToggle={mobileToolbarToggle}
      mobileAsideToggle={mobileAsideToggle}
      t={t}
    />
  );
};

export default HeaderMenu;
