import StrapiSDK from './StrapiSDK'
import { IFaqTags } from './types'
import { QUESTIONS_ENDPOINT } from './Questions'

export const FAQTAGS_ENDPOINT = `faq-tags?${QUESTIONS_ENDPOINT}_nin` // only return faq tags with questions

let _faqTags: IFaqTags[] = []

export class FaqTags {
  static async getFaqTags(locale: string) {
    _faqTags = await StrapiSDK.getEntries(FAQTAGS_ENDPOINT+`&_locale=${locale}`) as IFaqTags[]
    if (_faqTags.length === 0) _faqTags = await StrapiSDK.getEntries(FAQTAGS_ENDPOINT) as IFaqTags[]
    return _faqTags
  }
}