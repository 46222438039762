import React, { PureComponent, ReactNode } from "react";
import { AuthContext } from "../../../AuthContext";
import AsideBrand from "./AsideBrand";
import AsideMenu from "./AsideMenu";

interface Props {
  mobileAsideShow: boolean;
  mobileAsideToggle: () => void;
}

class AsideBase extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  hoverAside() {
    const cList = document.body.classList;
    if (
      cList.contains("aside--minimize") &&
      cList.contains("kt-aside--minimize") &&
      !cList.contains("kt-aside--minimize-hover")
    ) {
      cList.add("kt-aside--minimize-hover");
      cList.remove("kt-aside--minimize");
    }
  }

  hoverOutAside() {
    const cList = document.body.classList;
    if (
      cList.contains("aside--minimize") &&
      cList.contains("kt-aside--minimize-hover")
    ) {
      cList.remove("kt-aside--minimize-hover");
      cList.add("kt-aside--minimize");
    }
  }

  render(): ReactNode {
    return (
      <AuthContext.Consumer>
        {(context) => {
          return (
            <div
              className={`aside-base ${
                !context.authenticated ? "kt-aside-hide" : ""
              }`}
              onMouseOver={() => this.hoverAside()}
              onMouseLeave={() => this.hoverOutAside()}
            >
              <button
                className="kt-aside-close"
                id="kt_aside_close_btn"
                onClick={() => this.props.mobileAsideToggle()}
              >
                <i className="la la-close" />
              </button>
              <div
                className={`kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop${
                  this.props.mobileAsideShow ? " kt-aside--on" : ""
                }`}
                id="kt_aside"
              >
                <AsideBrand />
                <AsideMenu
                  mobileAsideToggle={() => this.props.mobileAsideToggle()}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/assets/picts/forest.png`}
                  alt=""
                  className="forest"
                />
                {/* <AsideFooter /> */}
              </div>
            </div>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default AsideBase;
